@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.checkbox-btn {
  display: flex;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: default;

    .checkbox-btn__checkbox-custom {
      transition: 0s;

      @include themify($themes) {
        background-color: themed('colorHover');
        border-color: themed('colorFieldsBorder');
      }

      svg {
        color: $text-color-muted;
      }
    }

    .checkbox-btn__label {
      color: $text-color-muted;
    }
  }

  &.checkbox-btn--colored {
    .checkbox-btn__checkbox-custom {
      border-color: $color-accent;
      background-color: $color-accent;

      svg {
        color: #FFF;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__checkbox-custom {
        border-color: $color-accent;
        background-color: $color-accent;
      }
    }
  }

  &.checkbox-btn--colored-click {
    .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
      border-color: $color-accent;
      background-color: $color-accent;

      svg {
        color: #FFF;
      }
    }

    &.disabled {
      .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
        @include themify($themes) {
          background-color: themed('colorHover');
          border-color: themed('colorFieldsBorder');
        }

        svg {
          color: $text-color-muted;
        }
      }
    }
  }

  &.checkbox-btn--button {
    background: $color-accent;
    min-width: 150px;
    color: #FFF;
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 0 6px;
    width: 100%;

    .checkbox-btn__label {
      color: #FFF;
      margin: auto auto auto 0;
      padding: 0;
    }

    .checkbox-btn__checkbox-custom {
      display: none;
    }

    .checkbox-btn__label-svg {
      margin: auto 4px auto auto;
      height: 16px;
      line-height: 1;

      svg {
        color: #FFF;
        width: 14px;
        height: 14px;
      }

      .checkbox-btn__label-check {
        display: none;
      }
    }

    .checkbox-btn__checkbox:checked ~ .checkbox-btn__label-svg {
      .checkbox-btn__label-check {
        display: block;
      }

      .checkbox-btn__label-uncheck {
        display: none;
      }
    }

    &:hover {
      background: $color-accent-hover;

      .checkbox-btn__label {
        color: #FFF;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__label {
        color: #FFF;
      }
    }
  }

  .checkbox-btn__checkbox {
    display: none;
  }

  .checkbox-btn__checkbox-custom {
    border-radius: 3px;
    border: 1px solid $text-color;
    height: 18px;
    position: relative;
    width: 18px;

    svg {
      color: $color-accent;
      height: 16px;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s;
      width: 16px;
    }
  }

  .checkbox-btn__label {
    line-height: 18px;
    padding-left: 0.5em;
  }

  .checkbox-btn__checkbox-custom,
  .checkbox-btn__label {
    display: inline-block;
    vertical-align: middle;
    transition: all 0.3s;
  }

  .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
    svg {
      opacity: 1;
    }
  }
}
