@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.folder-list-container {
  @include gutter-sizing('margin-bottom');

  > .folder-list {
    padding: 0;

    .folder-list {
      padding-left: $spacer;
    }
  }

  @include themify($themes) {
    border-top: 1px solid themed('cardColorHover');
  }

  ul > li:first-child {
    border-top: 0;
  }
}
