@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.modal {
  @include gutter-sizing([ 'padding' ], 2);
  // @include gutter-sizing([ 'padding-left', 'padding-right' ], 2);

  .modal-dialog {
    margin: 0 auto;

    @each $color, $value in $theme-colors {
      &.#{$color} .modal-content {
        border-left: 6px solid $value;
      }
    }
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    @include gutter-sizing([ 'padding-top', 'padding-bottom' ], 0.75);
    @include gutter-sizing([ 'padding-left', 'padding-right' ]);
  }

  .close-btn {
    box-shadow: $box-shadow;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 2;

    @media screen and (min-width: $bp-md) {
      font-size: rem(22);
      height: 60px;
      width: 60px;
    }
  }

  .modal-body {
    overflow: hidden;
  }

  .close-btn .modal-body {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .modal-body:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
