// Badges

$badge-font-size: 1em;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.75em;
$badge-border-radius: $border-radius;

$badge-pill-padding-x: 1em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;
