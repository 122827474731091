// Making some variables accessible everywhere
$bp-sm: 570px;
$bp-md: 760px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1920px;

// Overwriting bootstrap settings
$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-gutter-width-md: 20px;
$grid-gutter-width-xl: 30px;

// Adding to this map will update anywhere that uses the gutter sizing mixin
// The notable exception is in the objects/layout.scss,
// where we need to account for top/side bar widths
$grid-gutters: (
  0: $grid-gutter-width,
  $bp-md: $grid-gutter-width-md,
  $bp-xl: $grid-gutter-width-xl
);

// Topbar height
$topbar-height: 60px;

// Sidebar
$sidebar-expanded-width: 236px;
$sidebar-collapsed-width: 60px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
  xxl: $bp-xxl
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 1625px
);

@include _assert-ascending($container-max-widths, '$container-max-widths');
