// ==========================================================================
// Bonus functions, useful when setting up Bootstrap
// ==========================================================================

// Used a lot in the settings, can be changed to scale all the things
$rem-value: 16;

// Slightly lighten a color
// Mix a color with white to make totally blown out colors less likely
// https://sass-guidelin.es/#lightening-and-darkening-colors
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Slightly darken a color
// Mix a color with black to make totally blown out colors less likely
// https://sass-guidelin.es/#lightening-and-darkening-colors
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// em values
@function em($px, $font-size: $rem-value) {
  @return ($px / $font-size) * 1em;
}

// rem values
@function rem($px) {
  @return ($px / $rem-value) * 1rem;
}

// remove units
@function stripUnit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// To allow Boortstrap functions and mixins to work
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

// Prevents errors in case we forget to add variables from bootstrap
// (likely to happen when updating Bootstrap)
@import '~bootstrap/scss/variables';

@import 'animation';
@import 'box-shadow';
@import 'colors';
@import 'layout';
@import 'metrics';
@import 'printing';
@import 'typography';
@import 'z-index';

@import 'component-settings/alert';
@import 'component-settings/badge';
@import 'component-settings/breadcrumb';
@import 'component-settings/buttons';
@import 'component-settings/card';
@import 'component-settings/carousel';
@import 'component-settings/close';
@import 'component-settings/code';
@import 'component-settings/dropdown';
@import 'component-settings/figure';
@import 'component-settings/forms';
@import 'component-settings/jumbotron';
@import 'component-settings/list-group';
@import 'component-settings/modal';
@import 'component-settings/nav';
@import 'component-settings/pagination';
@import 'component-settings/popover';
@import 'component-settings/progress-bar';
@import 'component-settings/tables';
@import 'component-settings/thumbnail';
@import 'component-settings/tooltip';
