@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.story-preview {
  max-width: 100%;
  position: relative;

  .spinner-container {
    position: absolute;
    top: 42%;
    z-index: 3;
  }

  .frame {
    position: relative;
    z-index: 2;
  }

  .preview-container {
    border-radius: 20px;
    left: 13.5%;
    margin: auto;
    max-width: 73%;
    overflow: hidden;
    position: absolute;
    top: 4.6%;
    z-index: 1;

    .overlay {
      position: absolute;
      left: 0;
      width: 100%;

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
  }
}
