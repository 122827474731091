@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.post-preview {
  background-color: $background-gray-light-2;
  border: 1px solid $border-color;
  position: relative;

  .post-container {
    margin: auto;

    .slick-dots {
      background-color: rgba(0, 0, 0, 0.1);
      margin-top: -6px; // compensate for slick-list styles
    }
  }

  .post-actions {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;

    .btn {
      margin-bottom: 8px;
    }
  }

  .media-preview {
    line-height: 0;
  }

  img,
  video {
    display: block;
    margin: auto;
    max-height: 50vh;
    max-width: 100%;
  }
}
