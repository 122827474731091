@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$picker-background: white;
$selected-color: $primary;
$block-background: saturate(mix($primary, $picker-background, 15%), 100%);
$range-background: mix($primary, $picker-background, 70%);
$grid-width: 308px;

.datetime-field {
  .datetime-row {
    margin-left: ($grid-gutter-width / -2);
    margin-right: ($grid-gutter-width / -2);
  }

  .datetime-col {
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .timezone-col {
    @media screen and (max-width: $bp-sm) {
      margin-top: $grid-gutter-width;
    }
  }

  .flatpickr-input {
    padding-right: $input-padding-x; // overwrite :not(last-child) padding from Bootstrap
  }

  .form-control[readonly]:not([disabled]) {
    background-color: $input-bg;
    border-color: $input-border-color;
  }
}

.flatpickr-calendar {
  animation: none;
  background: $picker-background;
  border-radius: $border-radius;
  border: 0;
  box-shadow: $box-shadow-sm, $box-shadow;
  direction: ltr;
  display: none;
  font-size: $font-size-base;
  line-height: $line-height-base;
  margin-top: -4px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;

  &.open,
  &.inline {
    opacity: 1;
    visibility: visible;
  }

  &.open {
    display: inline-block;
    z-index: 999;
  }

  &.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.inline {
    display: block;
    position: relative;
    top: 2px;
  }

  &.static {
    position: absolute;
    top: calc(100% + 2px);
  }

  &.static.open {
    z-index: 999;
    display: block;
  }

  // unsure what these lines do but don't think we need them
  // &.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
  //   box-shadow: none !important;
  // }

  // &.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
  //   box-shadow: -2px 0 0 #E6E6E6, 5px 0 0 #E6E6E6;
  // }

  &:before,
  &:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }

  &.rightMost:before,
  &.rightMost:after {
    left: auto;
    right: 22px;
  }

  &:before {
    border-width: 5px;
    margin: 0 -5px;
  }

  &:after {
    border-width: 4px;
    margin: 0 -4px;
  }

  &.arrowBottom:before,
  &.arrowBottom:after {
    top: 100%;
  }

  &.arrowBottom:before {
    border-top-color: $background-gray-light-1;
  }

  &.arrowBottom:after {
    border-top-color: $background-gray-light-1;
  }

  input {
    appearance: none;
    border: 0;
    font-size: $font-size-base;
    height: 28px;
  }

  // Remove native arrows from number inputs

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {

    /* display: none; <- Crashes Chrome on hover */
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-appearance: textfield; /* Firefox */
  }
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: flex;
  padding: 15px 20px 10px;

  .flatpickr-month {
    border-radius: 5px 5px 0 0;
    color: $text-color;
    fill: $text-color;
    height: 28px;
    line-height: 1;
    text-align: center;
    position: relative;
    user-select: none;
    overflow: visible;
    flex: 1;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    border-radius: 14px;
    color: $text-color;
    cursor: pointer;
    fill: $text-color;
    height: 28px;
    height: auto;
    line-height: 16px;
    padding: 5px;
    text-decoration: none;
    width: 28px;
    width: auto;

    &.disabled {
      display: none;
    }

    i {
      position: relative;
    }

    &:hover {
      background-color: $block-background;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    svg path {
      transition: fill 0.1s;
      fill: inherit;
    }
  }
}

.flatpickr-monthDropdown-months {
  appearance: menulist;
  background: none;
  border: 1px solid $border-color-light;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0 8px 0 0;
  outline: none;
  padding: 2px;
  position: relative;
  vertical-align: initial;
  width: auto;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .flatpickr-monthDropdown-month {
    background-color: $picker-background;
    outline: none;
    padding: 0;
  }
}

.numInputWrapper {
  position: relative;
  height: auto;

  input {
    width: 100%;
  }

  input::-ms-clear {
    display: none;
  }

  span {
    cursor: pointer;
    display: inline-block;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    padding: 0 4px 0 2px;
    position: absolute;
    right: 0;
    width: 14px;
    transition: opacity 0.15s linear;

    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &.arrowUp {
      top: 0;

      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid $text-color-muted;
        top: 40%;
      }

      &:hover:after {
        border-bottom-color: $primary;
      }
    }

    &.arrowDown {
      top: 50%;

      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $text-color-muted;
        bottom: 40%;
      }

      &:hover:after {
        border-top-color: $primary;
      }
    }

    svg {
      width: inherit;
      height: auto;
    }

    svg path {
      fill: rgba(90, 97, 113, 0.5);
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:hover span {
    opacity: 1;
  }
}

.flatpickr-current-month {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  height: 100%;
  justify-content: center;
  left: 12.5%;
  line-height: 1;
  line-height: inherit;
  padding: 0;
  position: absolute;
  transform: translate3d(0, 0, 0);
  width: 75%;

  .numInputWrapper span {
    left: 100%;
    right: auto;
  }

  span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }

  span.cur-month:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .numInputWrapper {
    width: 4em;
    display: inline-block;
  }

  input.cur-year {
    width: 4.5em;
  }
}

.flatpickr-weekdays {
  background: $picker-background;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;

  .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
  }
}

.flatpickr-weekday {
  cursor: default;
  color: $text-color-muted;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-size: $font-size-sm;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: $grid-width;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: $grid-width;
  min-width: $grid-width;
  max-width: $grid-width;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 $background-gray-light-1;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  color: $text-color;
  cursor: pointer;
  font-weight: 400;
  width: 14.285714%;
  flex-basis: 14.285714%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: $range-background;
    border-color: $range-background;
    color: white;

    &:hover {
      background-color: $range-background;
    }
  }

  &.today {
    border-color: $border-color-light;

    &:hover {
      border-color: $range-background;
    }
  }

  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background: $primary;
    box-shadow: none;
    color: #FFF;
    border-color: $primary;
  }

  &.selected.startRange,
  &.startRange.startRange,
  &.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }

  &.selected.endRange,
  &.startRange.endRange,
  &.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }

  &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 $primary;
  }

  &.selected.startRange.endRange,
  &.startRange.startRange.endRange,
  &.endRange.startRange.endRange {
    border-radius: 50px;
  }

  &.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 $range-background, 5px 0 0 $range-background;
  }

  &.disabled,
  &.disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: rgba($text-color, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
  }

  &.disabled,
  &.disabled:hover {
    cursor: not-allowed;
    color: rgba($text-color, 0.1);
  }

  &.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
  }

  &.hidden {
    visibility: hidden;
  }
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;

  .flatpickr-weeks {
    padding: 0 12px;
    border-left: 1px solid $background-gray-light-1;
  }

  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }

  span.flatpickr-day,
  span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(72, 72, 72, 0.3);
    background: transparent;
    cursor: default;
    border: none;
  }
}

.flatpickr-innerContainer {
  display: block;
  padding: 0 20px 20px;
  display: flex;
  overflow: hidden;
  background: #FFF;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
}

.flatpickr-time {
  align-items: center;
  background-color: $block-background;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  display: flex;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 8px;

  // .numInputWrapper span.arrowUp:after {
  //   border-bottom-color: $text-color-muted;
  // }

  // .numInputWrapper span.arrowDown:after {
  //   border-top-color: $text-color-muted;
  // }

  input {
    width: 60px;
  }

  .flatpickr-time-separator {
    margin: auto 0.5em;
  }

  .flatpickr-time-separator,
  .flatpickr-am-pm {
    display: inline-block;
    color: $text-color-muted;
    user-select: none;
  }

  .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }

  input:hover,
  .flatpickr-am-pm:hover,
  input:focus,
  .flatpickr-am-pm:focus {
    background: $picker-background;
  }
}

.flatpickr-input[readonly] {
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('cardColorHover');
    color: themed('cardText');
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

span.flatpickr-day.selected {
  font-weight: bold;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #F9F9F9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #EEE;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428BCA;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428BCA;
  color: #FFF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #FFF;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #F9F9F9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  // -webkit-user-select: none;   /* Chrome/Safari/Opera */
  // -khtml-user-select: none;    /* Konqueror */
  // -moz-user-select: none;      /* Firefox */
  // -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #EEE;
}

.rdtPicker tfoot {
  border-top: 1px solid #F9F9F9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #EEE;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #EEE;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  user-select: none;

  // stylelint-disable-next-line vendor-prefix
  -webkit-touch-callout: none;
}

.rdtCounter .rdtBtn:hover {
  background: #EEE;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}
