/*

labels
legends
fills
strokes
gradients

*/

.chart {
  path.recharts-sector {
    @include themify($themes) {
      stroke: themed('cardBackground');
    }
  }

  .recharts-text {
    fill: $text-color-muted;
    font-weight: $font-weight-light;
    opacity: 0.7;
  }

  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line {
    stroke: $border-color-light;
  }

  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    fill: $border-color-light;
    opacity: 0.4;
  }

  .recharts-brush-slide {
    fill: $gray-300;
    fill-opacity: 1;
  }

  .recharts-brush-traveller {
    transform: translateX(-8px);

    rect {
      fill: white;
      stroke: $gray-300;
      width: 12px;
    }

    line {
      stroke: $gray-300;
      transform: translateX(4px);
    }

    &:first-of-type {
      transform: translateX(0);
    }
  }

  .recharts-tooltip-cursor {
    fill-opacity: 0.2;
  }

  .recharts-legend-wrapper {
    color: $text-color-medium;
  }

  .recharts-default-tooltip {
    background-color: white;
    border-radius: $border-radius;
    border: 1px solid $border-color-light;
    padding: $spacer / 2;
    white-space: nowrap;

    .recharts-total-tooltip {
      margin: 0;
    }
  }

  .chart-legend {
    padding: $spacer 0 0;
    list-style: none;

    li {
      margin-bottom: 0.5em;
    }

    .legend-color-sample {
      border-radius: 50%;
      display: inline-block;
      height: 0.8em;
      margin-right: 0.5em;
      width: 0.8em;
    }
  }
}
