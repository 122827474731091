@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.slick-dots {
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: static;

  li {
    display: block;
    margin: 0 2px;
    width: auto;

    button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 4px;
      position: static;
      width: 10px;

      &:before {
        position: static;
      }
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: black;
}
