@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.image-select {
  box-shadow: 0 0 0 0 $white, 0 0 0 0 $green;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.2s;
  width: auto;
  will-change: box-shadow;

  &.selected {
    box-shadow: 0 0 0 2px $white, 0 0 0 6px $green;
  }

  .caption {
    margin: 0.5em 0 0.4em;
    text-align: center;
  }
}
