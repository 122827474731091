@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.add-page {
  @include gutter-sizing('padding-top');

  .page-thumbnail {
    @include gutter-sizing('margin-bottom');
  }
}
