// Navs

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1.5rem;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $gray-200;
$nav-divider-margin-y: ($spacer / 2);

// Navbar
$navbar-padding-y: ($spacer / 2);
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: (
  $font-size-base * $line-height-base + $nav-link-padding-y * 2
);
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height)/ 2;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: 0;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), '#', '%23');
$navbar-dark-toggler-border-color: transparent;

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), '#', '%23');
$navbar-light-toggler-border-color: rgba($black, 0.1);
