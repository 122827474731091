@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  option {
    @include themify($themes) {
      background-color: themed('color-background');
    }
  }
}

div.rs__control {
  border-color: $input-border-color;
  border-radius: $border-radius;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: $input-font-size;
  height: auto;
  min-height: 30px;
  min-width: 122px;
  width: 100%;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    min-height: 30px;
  }

  &:hover {
    border-color: $input-border-color;
  }

  &--is-focused {
    box-shadow: $input-btn-focus-box-shadow;
  }

  .rs__input {
    display: block;
    height: 16px;
    justify-self: center;
    margin: 0 2px;
    padding: 0;

    input {
      height: 16px;

      &:focus {
        border: 0;
        box-shadow: none;
      }
    }
  }

  .rs__clear-indicator {
    color: $text-muted;
    padding-bottom: 0;
    padding-top: 0;
  }

  .rs__dropdown-indicator {
    color: $border-color;
    padding-bottom: 0;
    padding-top: 0;
  }

  .rs__indicator-separator {
    margin: 0;
    background-color: $border-color;
  }

  .rs__placeholder {
    color: $text-color-muted;
    position: static;
    transform: none;
  }

  .rs__value-container {
    min-height: 30px;
    overflow: initial;
    padding: 2px 10px;
  }

  .rs__value-container--is-multi {
    padding: 2px 5px;
  }

  .rs__single-value {
    display: block;
    justify-self: center;
    margin: 0 2px 0 0;
    position: static;
    top: auto;
    transform: none;
  }

  .rs__multi-value {
    align-items: center;
    background-color: #49C6E8;
    border-radius: $border-radius-sm;
    color: white;
    display: flex;
    font-size: inherit;
    height: 22px;
    margin: 2px 4px 2px 0;
    overflow: hidden;

    + [class^='css-']:last-child {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 18px;
      display: flex;
      align-items: center;
    }
  }

  .rs__multi-value__label {
    color: inherit;
    font-size: inherit;
    line-height: 1;
    padding: 0 10px;
  }

  .rs__multi-value__remove {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px 5px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: inherit;
    }
  }
}

// div for better specificity than the default styles

div.rs__menu {
  @include themify($themes) {
    background-color: themed('color-background');
  }
  border: 0;
  box-shadow: none;
  margin: -1px 0 0;
  padding: 0;

  .rs__menu-list {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
    margin: 0;
    padding: 0;
  }

  .rs__option {
    background-color: $background-gray-light-1;
    border: 0;
    color: $text-color-muted;
    cursor: pointer;
    font-size: inherit;

    &:hover,
    &--is-focused {
      background-color: #FFF;
      color: $text-color;
    }

    // &--is-selected {}
  }

  .rs__no-options-message {
    background-color: $background-gray-light-1;
    color: $text-color-muted;
    cursor: pointer;
    font-size: inherit;
    padding: 20px;
  }
}
