@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.toggle-btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  margin: 3px 0 3px 4px;
  padding: 4px 0;

  @media screen and (min-width: $bp-md) {
    margin: 0;
  }

  .toggle-btn__input {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  .toggle-btn__input-label {
    background-color: $gray-200;
    border-radius: 20px;
    border: 1px solid $gray-200;
    cursor: pointer;
    display: block;
    height: 18px;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    text-indent: -9999px;
    width: 38px;

    &:after {
      background-color: #FFF;
      border-radius: 50%;
      box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
      content: '';
      height: 20px;
      left: -2px;
      position: absolute;
      top: -2px;
      transition: 0.3s;
      transition: left 0.2s ease-in-out;
      width: 20px;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.1875rem rgba($color-accent, 0.25);
      border-color: $color-accent;
    }
  }

  .toggle-btn__input:checked ~ .toggle-btn__input-label {
    &:after {
      background: $color-accent;
      left: calc(100% - 16px);
      // transform: translateX(-100%);
    }
  }
}
