p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.3em;
  margin-top: 0;

  .subhead {
    font-size: 0.8em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.typography--inline {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-top: auto;
    margin-right: 15px;
  }
}

.typography-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }
}

.highlight {
  background-color: $color-accent;
  color: #FFF;
}

.page-subhead {
  margin: 0 0 20px;

  &.subhead {
    font-size: 14px;
    color: $text-color-muted-on-dark;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

::selection {
  color: #FFF;
  background: $cyan;
}
