// Breadcrumbs

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 0.25rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: none;

$breadcrumb-border-radius: 0;
