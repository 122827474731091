$rootPath: '../';

.account-page {
  background-color: #EBEDEF;
}

.account {
  min-height: 100vh;
  display: flex;
  overflow: visible;

  &.account-photo {
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #FFF;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #EFF1F5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #FFF;

      &:focus {
        border-color: #EFF1F5;
      }
    }

    p {
      color: #FFF;
    }

    .account__title {
      color: #FFF;
    }
  }

  .account__wrapper {
    margin: 20px auto;
    position: relative;
    z-index: 1;
  }

  .account__head,
  .account-form {
    @include gutter-sizing([ 'padding-left', 'padding-right' ], 2);
    @include gutter-sizing([ 'padding-bottom', 'padding-top' ]);
  }

  .account__card {
    background-color: #F6F6F6;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1), 0 4px 32px rgba(0, 0, 0, 0.04);
    margin: auto;
    max-width: 520px;
    width: 100%;
  }

  .account__head {
    background-color: $background-gray-dark-3;

    .logo {
      display: block;
      margin: 0 auto;
      max-width: 290px;
      width: 100%;
    }
  }

  .account__logo-accent {
    color: $color-blue;
  }

  .account__have-account {
    text-align: center;
    margin-top: 20px;

    a {
      color: $color-blue;
      transition: all 0.3s;

      &:hover {
        color: $color-blue-hover;
        text-decoration: none;
      }
    }
  }

  .artwork-container {
    position: relative;
    z-index: 0;

    .artwork {
      margin: 7% auto 0;
      max-height: 90vh;
    }
  }
}
