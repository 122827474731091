@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.form-actions {
  position: sticky;
  top: 92px;

  .tab-content .tab-pane {
    padding-top: 20px;
  }
}
