@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.dropzone {
  @include transition($btn-transition);
  align-items: center;
  border-radius: $border-radius-sm;
  cursor: pointer;
  display: flex;
  min-height: 80px;
  position: relative;
  width: 100%;

  @include themify($themes) {
    background-color: themed('cardColorHover');
    border: 1px solid themed('colorFieldsBorder');
  }

  &.thumbnail {
    // A smaller dropzone for profile pictures etc.
    height: 128px;
    overflow: hidden;
    width: 128px;

    .dropzone-drop-here {
      font-size: 11px;
      line-height: (14/11);
      padding: 0 8px;
    }

    .remove {
      bottom: 12px;
      height: auto;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
    }
  }

  .dropzone-input {
    cursor: pointer;
    display: flex;
    height: 100%;
    margin: auto;
  }

  .dropzone-drop-here {
    color: $input-placeholder-color;
    font-size: $input-font-size;
    margin: auto;
    padding: $btn-padding-y $btn-padding-x;
    text-align: center;

    @media screen and (min-width: $bp-md) {
      font-size: $font-size-base-md-up;
    }

    .dropzone-file-icons svg {
      color: $background-gray-light-3;
      font-size: 64px;
      margin: 0 12px 8px;
    }
  }

  .files-wrapper {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 0 12px;
    width: calc(100% + 30px);
  }

  &.circular {
    .file-preview,
    .file-preview:before,
    .image-component {
      border-radius: 50em;
    }
  }
}
