.container-aspect-ratio {
  height: 0;
  position: relative;

  .contain-image {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.container-16x9 {
  padding-bottom: (100% * 9 / 16);
}

.container-9x16 {
  padding-bottom: (100% * 16 / 9);
}

.container-1x1 {
  padding-bottom: 100%;
}

.container-4x3 {
  padding-bottom: (100% * 3 / 4);
}

.container-3x4 {
  padding-bottom: (100% * 4 / 3);
}
