:export {
  primary: #58C1DB;
  secondary: #A3A3A3;
  success: #58CB87;
  info: #586DDB;
  warning: #F9F48D;
  danger: #CC5B42;
  light: #F5F9F9;
  dark: #1A1A1A;
  purple: #AB6DC8;
  yellow: #F9F48D;
  orange: #E19D4E;
  green: #58CB87;
  red: #CC5B42;
  cyan: #58C1DB;
  blue: #586DDB;
  chart-color-1: #58C1DB;
  chart-color-2: #20C997;
  chart-color-3: #58CB87;
  chart-color-4: #F9F48D;
  chart-color-5: #E19D4E;
  chart-color-6: #CC5B42;
  chart-color-7: #E376B7;
  chart-color-8: #AB6DC8;
  chart-color-9: #5647AE;
  chart-color-0: #586DDB;
  chart-color-hc-1: #58C1DB;
  chart-color-hc-2: #F9F48D;
  chart-color-hc-3: #E376B7;
  chart-color-hc-4: #5647AE;
  chart-color-hc-5: #20C997;
  chart-color-hc-6: #E19D4E;
  chart-color-hc-7: #AB6DC8;
  chart-color-hc-8: #58CB87;
  chart-color-hc-9: #CC5B42;
  chart-color-hc-0: #586DDB; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #586DDB;
  --indigo: #5647AE;
  --purple: #AB6DC8;
  --pink: #E376B7;
  --red: #CC5B42;
  --orange: #E19D4E;
  --yellow: #F9F48D;
  --green: #58CB87;
  --teal: #20C997;
  --cyan: #58C1DB;
  --white: #FFF;
  --gray: #6C757D;
  --gray-dark: #343A40;
  --primary: #58C1DB;
  --secondary: #A3A3A3;
  --success: #58CB87;
  --info: #586DDB;
  --warning: #F9F48D;
  --danger: #CC5B42;
  --light: #FFF;
  --dark: #2D2D2F;
  --breakpoint-xs: 0;
  --breakpoint-sm: 570px;
  --breakpoint-md: 760px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1920px;
  --font-family-sans-serif: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1578A2;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1ca0d8;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #A3A3A3;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.33333;
  color: inherit; }

h1, .h1 {
  font-size: 2.25rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.35rem + 1.2vw) ; } }

h2, .h2 {
  font-size: 1.875rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.3125rem + 0.75vw) ; } }

h3, .h3 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 0.8125rem; }

h6, .h6 {
  font-size: 0.6875rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.33333; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.33333; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.33333; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.33333; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #A3A3A3; }

small,
.small {
  font-size: 0.75rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #FCF8E3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #A3A3A3; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #DEE2E6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6C757D; }

code {
  font-size: 87.5%;
  color: #E376B7;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 570px) {
    .container {
      max-width: 1625px; } }

.container-fluid {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 570px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 760px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #DEE2E6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #DEE2E6; }
  .table tbody + tbody {
    border-top: 2px solid #DEE2E6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #DEE2E6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DEE2E6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d0eef5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a8dfec; }

.table-hover .table-primary:hover {
  background-color: #bbe6f1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bbe6f1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e5e5e5; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #cfcfcf; }

.table-hover .table-secondary:hover {
  background-color: #d8d8d8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d8d8d8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d0f0dd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a8e4c1; }

.table-hover .table-success:hover {
  background-color: #bdeacf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bdeacf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d0d6f5; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a8b3ec; }

.table-hover .table-info:hover {
  background-color: #bbc4f1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bbc4f1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fdfcdf; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fcf9c4; }

.table-hover .table-warning:hover {
  background-color: #fcfac7; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fcfac7; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1d1ca; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e4aa9d; }

.table-hover .table-danger:hover {
  background-color: #ecbfb6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ecbfb6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c4c5; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #929293; }

.table-hover .table-dark:hover {
  background-color: #b7b7b8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7b7b8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #E9ECEF;
  border-color: #DEE2E6; }

.table-dark {
  color: #FFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 569.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 759.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, input {
      transition: none; } }
  .form-control::-ms-expand, input::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, input:focus {
    color: #495057;
    background-color: #FFF;
    border-color: #b9e9cd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
  .form-control::placeholder, input::placeholder {
    color: #6C757D;
    opacity: 1; }
  .form-control:disabled, input:disabled, .form-control[readonly], input[readonly] {
    background-color: #E9ECEF;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.2rem + 1px);
  padding-bottom: calc(0.2rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.4rem + 2px);
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #A3A3A3; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated input:valid, .form-control.is-valid, input.is-valid {
  border-color: #28a745;
  padding-right: 1.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated input:valid:focus, .form-control.is-valid:focus, input.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated input:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated input:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, input.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  input.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated input:invalid, .form-control.is-invalid, input.is-invalid {
  border-color: #dc3545;
  padding-right: 1.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated input:invalid:focus, .form-control.is-invalid:focus, input.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated input:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated input:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, input.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  input.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 570px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline input {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFF;
  background-color: #58C1DB;
  border-color: #58C1DB; }
  .btn-primary:hover {
    color: #FFF;
    background-color: #39b5d4;
    border-color: #2eb1d2; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 202, 224, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFF;
    background-color: #58C1DB;
    border-color: #58C1DB; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFF;
    background-color: #2eb1d2;
    border-color: #2ba9c8; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 202, 224, 0.5); }

.btn-secondary {
  color: #FFF;
  background-color: #A3A3A3;
  border-color: #A3A3A3; }
  .btn-secondary:hover {
    color: #FFF;
    background-color: #909090;
    border-color: #8a8a8a; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #8a8a8a;
    border-color: #838383; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(177, 177, 177, 0.5); }

.btn-success {
  color: #FFF;
  background-color: #58CB87;
  border-color: #58CB87; }
  .btn-success:hover {
    color: #FFF;
    background-color: #3cc172;
    border-color: #39b76c; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 211, 153, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFF;
    background-color: #58CB87;
    border-color: #58CB87; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFF;
    background-color: #39b76c;
    border-color: #36ad67; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 211, 153, 0.5); }

.btn-info {
  color: #FFF;
  background-color: #586DDB;
  border-color: #586DDB; }
  .btn-info:hover {
    color: #FFF;
    background-color: #3951d4;
    border-color: #2e48d2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 131, 224, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFF;
    background-color: #586DDB;
    border-color: #586DDB; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFF;
    background-color: #2e48d2;
    border-color: #2b44c8; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(113, 131, 224, 0.5); }

.btn-warning {
  color: #444647;
  background-color: #F9F48D;
  border-color: #F9F48D; }
  .btn-warning:hover {
    color: #444647;
    background-color: #f7f069;
    border-color: #f6ef5d; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 218, 131, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #444647;
    background-color: #F9F48D;
    border-color: #F9F48D; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #444647;
    background-color: #f6ef5d;
    border-color: #f6ee50; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 218, 131, 0.5); }

.btn-danger {
  color: #FFF;
  background-color: #CC5B42;
  border-color: #CC5B42; }
  .btn-danger:hover {
    color: #FFF;
    background-color: #b74931;
    border-color: #ac452f; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 116, 94, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFF;
    background-color: #CC5B42;
    border-color: #CC5B42; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFF;
    background-color: #ac452f;
    border-color: #a2412c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 116, 94, 0.5); }

.btn-light {
  color: #444647;
  background-color: #FFF;
  border-color: #FFF; }
  .btn-light:hover {
    color: #444647;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 227, 227, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #444647;
    background-color: #FFF;
    border-color: #FFF; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #444647;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(227, 227, 227, 0.5); }

.btn-dark {
  color: #FFF;
  background-color: #2D2D2F;
  border-color: #2D2D2F; }
  .btn-dark:hover {
    color: #FFF;
    background-color: #1a1a1b;
    border-color: #141415; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 77, 78, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFF;
    background-color: #2D2D2F;
    border-color: #2D2D2F; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFF;
    background-color: #141415;
    border-color: #0e0e0e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 77, 78, 0.5); }

.btn-outline-primary {
  color: #58C1DB;
  border-color: #58C1DB; }
  .btn-outline-primary:hover {
    color: #FFF;
    background-color: #58C1DB;
    border-color: #58C1DB; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 193, 219, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #58C1DB;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFF;
    background-color: #58C1DB;
    border-color: #58C1DB; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 193, 219, 0.5); }

.btn-outline-secondary {
  color: #A3A3A3;
  border-color: #A3A3A3; }
  .btn-outline-secondary:hover {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #A3A3A3;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #A3A3A3;
    border-color: #A3A3A3; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }

.btn-outline-success {
  color: #58CB87;
  border-color: #58CB87; }
  .btn-outline-success:hover {
    color: #FFF;
    background-color: #58CB87;
    border-color: #58CB87; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #58CB87;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFF;
    background-color: #58CB87;
    border-color: #58CB87; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.5); }

.btn-outline-info {
  color: #586DDB;
  border-color: #586DDB; }
  .btn-outline-info:hover {
    color: #FFF;
    background-color: #586DDB;
    border-color: #586DDB; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 109, 219, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #586DDB;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFF;
    background-color: #586DDB;
    border-color: #586DDB; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 109, 219, 0.5); }

.btn-outline-warning {
  color: #F9F48D;
  border-color: #F9F48D; }
  .btn-outline-warning:hover {
    color: #444647;
    background-color: #F9F48D;
    border-color: #F9F48D; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 244, 141, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F9F48D;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #444647;
    background-color: #F9F48D;
    border-color: #F9F48D; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 244, 141, 0.5); }

.btn-outline-danger {
  color: #CC5B42;
  border-color: #CC5B42; }
  .btn-outline-danger:hover {
    color: #FFF;
    background-color: #CC5B42;
    border-color: #CC5B42; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 91, 66, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #CC5B42;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFF;
    background-color: #CC5B42;
    border-color: #CC5B42; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 91, 66, 0.5); }

.btn-outline-light {
  color: #FFF;
  border-color: #FFF; }
  .btn-outline-light:hover {
    color: #444647;
    background-color: #FFF;
    border-color: #FFF; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FFF;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #444647;
    background-color: #FFF;
    border-color: #FFF; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #2D2D2F;
  border-color: #2D2D2F; }
  .btn-outline-dark:hover {
    color: #FFF;
    background-color: #2D2D2F;
    border-color: #2D2D2F; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 45, 47, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2D2D2F;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFF;
    background-color: #2D2D2F;
    border-color: #2D2D2F; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(45, 45, 47, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1578A2;
  text-decoration: none; }
  .btn-link:hover {
    color: #1ca0d8;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6C757D;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.4rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.25s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 570px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 760px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #E9ECEF; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #F8F9FA; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFF;
    text-decoration: none;
    background-color: #58CB87; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6C757D;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6C757D;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > input,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > input + .form-control, .input-group > .form-control + input, .input-group > input + input,
    .input-group > .form-control + .custom-select,
    .input-group > input + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > input + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + input,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + input,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + input,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .input-group > input:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > input:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > input:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #E9ECEF;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > input:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > input,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > input:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.4rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > input,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #007bff;
    background-color: #58CB87; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #e0f5e9;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6C757D; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #E9ECEF; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #DEE2E6;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #58CB87; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(88, 193, 219, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(88, 193, 219, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(88, 193, 219, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #DEE2E6;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(88, 193, 219, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFF;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #b9e9cd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 233, 205, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6C757D;
    background-color: #E9ECEF; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.4rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #b9e9cd;
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #E9ECEF; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  border: 1px solid #A3A3A3;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 1.5;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #E9ECEF;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #58CB87;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #e0f5e9; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DEE2E6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #58CB87;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #e0f5e9; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DEE2E6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #58CB87;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #e0f5e9; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #DEE2E6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #DEE2E6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1.5rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6C757D;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #DEE2E6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #E9ECEF #E9ECEF #DEE2E6; }
    .nav-tabs .nav-link.disabled {
      color: #6C757D;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #DEE2E6 #DEE2E6 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #58CB87; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 569.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 570px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 759.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 760px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1919.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: 1px solid rgba(128, 128, 128, 0.075);
  border-radius: 0.4rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem; }

.card-body {
  flex: 1 1 auto;
  padding: 16px; }

.card-title {
  margin-bottom: 1.25rem; }

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 16px; }

.card-header {
  padding: 1.25rem 16px;
  margin-bottom: 0;
  background-color: inherit;
  border-bottom: 1px solid rgba(128, 128, 128, 0.075); }
  .card-header:first-child {
    border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.25rem 16px;
  background-color: inherit;
  border-top: 1px solid rgba(128, 128, 128, 0.075); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px); }

.card-header-tabs {
  margin-right: -8px;
  margin-bottom: -1.25rem;
  margin-left: -8px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -8px;
  margin-left: -8px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.4rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 16px; }
  @media (min-width: 570px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -16px;
      margin-left: -16px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 16px;
        margin-bottom: 0;
        margin-left: 16px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 16px; }
  @media (min-width: 570px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.25rem; }

@media (min-width: 570px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0.25rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6C757D;
    content: none; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6C757D; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1578A2;
  background-color: #FFF;
  border: 1px solid #DEE2E6; }
  .page-link:hover {
    z-index: 2;
    color: #1ca0d8;
    text-decoration: none;
    background-color: #E9ECEF;
    border-color: #DEE2E6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #58CB87;
  border-color: #58CB87; }

.page-item.disabled .page-link {
  color: #6C757D;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #DEE2E6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFF;
  background-color: #58C1DB; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #2eb1d2; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 193, 219, 0.5); }

.badge-secondary {
  color: #FFF;
  background-color: #A3A3A3; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFF;
    background-color: #8a8a8a; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #58CB87; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #39b76c; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.5); }

.badge-info {
  color: #FFF;
  background-color: #586DDB; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFF;
    background-color: #2e48d2; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 109, 219, 0.5); }

.badge-warning {
  color: #444647;
  background-color: #F9F48D; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #444647;
    background-color: #f6ef5d; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(249, 244, 141, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #CC5B42; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #ac452f; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 91, 66, 0.5); }

.badge-light {
  color: #444647;
  background-color: #FFF; }
  a.badge-light:hover, a.badge-light:focus {
    color: #444647;
    background-color: #e6e6e6; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #2D2D2F; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #141415; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(45, 45, 47, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #E9ECEF;
  border-radius: 0.4rem; }
  @media (min-width: 570px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2e6472;
  background-color: #def3f8;
  border-color: #d0eef5; }
  .alert-primary hr {
    border-top-color: #bbe6f1; }
  .alert-primary .alert-link {
    color: #1f444e; }

.alert-secondary {
  color: #555555;
  background-color: #ededed;
  border-color: #e5e5e5; }
  .alert-secondary hr {
    border-top-color: #d8d8d8; }
  .alert-secondary .alert-link {
    color: #3c3c3c; }

.alert-success {
  color: #2e6a46;
  background-color: #def5e7;
  border-color: #d0f0dd; }
  .alert-success hr {
    border-top-color: #bdeacf; }
  .alert-success .alert-link {
    color: #1f462f; }

.alert-info {
  color: #2e3972;
  background-color: #dee2f8;
  border-color: #d0d6f5; }
  .alert-info hr {
    border-top-color: #bbc4f1; }
  .alert-info .alert-link {
    color: #1f274e; }

.alert-warning {
  color: #817f49;
  background-color: #fefde8;
  border-color: #fdfcdf; }
  .alert-warning hr {
    border-top-color: #fcfac7; }
  .alert-warning .alert-link {
    color: #605f37; }

.alert-danger {
  color: #6a2f22;
  background-color: #f5ded9;
  border-color: #f1d1ca; }
  .alert-danger hr {
    border-top-color: #ecbfb6; }
  .alert-danger .alert-link {
    color: #431e16; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: #171718;
  background-color: #d5d5d5;
  border-color: #c4c4c5; }
  .alert-dark hr {
    border-top-color: #b7b7b8; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1.5em 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1.5em;
  overflow: hidden;
  font-size: 1em;
  background-color: rgba(125, 125, 125, 0.15);
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #58C1DB;
  transition: width 0.4s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.5em 1.5em; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #F8F9FA; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #E9ECEF; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  margin-bottom: -1px;
  background-color: #FFF;
  border: 1px solid #d1d1d1; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6C757D;
    pointer-events: none;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #444647;
    background-color: #FFF;
    border-color: #58CB87; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 570px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 760px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1920px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #2e6472;
  background-color: #d0eef5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2e6472;
    background-color: #bbe6f1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #2e6472;
    border-color: #2e6472; }

.list-group-item-secondary {
  color: #555555;
  background-color: #e5e5e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #555555;
    background-color: #d8d8d8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #555555;
    border-color: #555555; }

.list-group-item-success {
  color: #2e6a46;
  background-color: #d0f0dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2e6a46;
    background-color: #bdeacf; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #2e6a46;
    border-color: #2e6a46; }

.list-group-item-info {
  color: #2e3972;
  background-color: #d0d6f5; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2e3972;
    background-color: #bbc4f1; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #2e3972;
    border-color: #2e3972; }

.list-group-item-warning {
  color: #817f49;
  background-color: #fdfcdf; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #817f49;
    background-color: #fcfac7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #817f49;
    border-color: #817f49; }

.list-group-item-danger {
  color: #6a2f22;
  background-color: #f1d1ca; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6a2f22;
    background-color: #ecbfb6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #6a2f22;
    border-color: #6a2f22; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: #171718;
  background-color: #c4c4c5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #171718;
    background-color: #b7b7b8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #171718;
    border-color: #171718; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 2rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 4rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 4rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 4rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 4rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(209, 209, 209, 0.3);
  border-radius: 0.25rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #1A1A1A; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.7; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(209, 209, 209, 0.3);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 16px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid rgba(209, 209, 209, 0.3);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 570px) {
  .modal-dialog {
    max-width: 838px;
    margin: 2rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 4rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 4rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 4rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 4rem); }
  .modal-sm {
    max-width: 600px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1880px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.4rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.4rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #58C1DB !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2eb1d2 !important; }

.bg-secondary {
  background-color: #A3A3A3 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8a8a8a !important; }

.bg-success {
  background-color: #58CB87 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #39b76c !important; }

.bg-info {
  background-color: #586DDB !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2e48d2 !important; }

.bg-warning {
  background-color: #F9F48D !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f6ef5d !important; }

.bg-danger {
  background-color: #CC5B42 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ac452f !important; }

.bg-light {
  background-color: #FFF !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #2D2D2F !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #141415 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #A3A3A3 !important; }

.border-top {
  border-top: 1px solid #A3A3A3 !important; }

.border-right {
  border-right: 1px solid #A3A3A3 !important; }

.border-bottom {
  border-bottom: 1px solid #A3A3A3 !important; }

.border-left {
  border-left: 1px solid #A3A3A3 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #58C1DB !important; }

.border-secondary {
  border-color: #A3A3A3 !important; }

.border-success {
  border-color: #58CB87 !important; }

.border-info {
  border-color: #586DDB !important; }

.border-warning {
  border-color: #F9F48D !important; }

.border-danger {
  border-color: #CC5B42 !important; }

.border-light {
  border-color: #FFF !important; }

.border-dark {
  border-color: #2D2D2F !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.4rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 570px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 760px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 570px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 760px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 570px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 760px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.15rem 0.4rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 570px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 760px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 570px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 760px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #58C1DB !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #29a0be !important; }

.text-secondary {
  color: #A3A3A3 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #7d7d7d !important; }

.text-success {
  color: #58CB87 !important; }

a.text-success:hover, a.text-success:focus {
  color: #33a461 !important; }

.text-info {
  color: #586DDB !important; }

a.text-info:hover, a.text-info:focus {
  color: #2941be !important; }

.text-warning {
  color: #F9F48D !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f5ed44 !important; }

.text-danger {
  color: #CC5B42 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #983d29 !important; }

.text-light {
  color: #FFF !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #2D2D2F !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #080808 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #A3A3A3 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #ADB5BD;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DEE2E6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #DEE2E6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #DEE2E6; } }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }
  .rv-xy-plot canvas {
    pointer-events: none; }
  .rv-xy-plot .rv-xy-canvas {
    pointer-events: none;
    position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series,
.rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }
  .rv-discrete-color-legend.horizontal {
    white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }
  .rv-discrete-color-legend-item.horizontal {
    display: inline-block; }
    .rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
      margin-left: 0;
      display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }
  .rv-discrete-color-legend-item.clickable:hover {
    background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }
  .rv-continuous-color-legend .rv-gradient {
    height: 4px;
    border-radius: 2px;
    margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }
  .rv-continuous-size-legend .rv-bubbles {
    text-align: justify;
    overflow: hidden;
    margin-bottom: 5px;
    width: 100%; }
  .rv-continuous-size-legend .rv-bubble {
    background: #d8d9dc;
    display: inline-block;
    vertical-align: bottom; }
  .rv-continuous-size-legend .rv-spacer {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left,
.rv-legend-titles__right,
.rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }
  .rv-xy-plot canvas {
    pointer-events: none; }
  .rv-xy-plot .rv-xy-canvas {
    pointer-events: none;
    position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series,
.rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }
  .rv-discrete-color-legend.horizontal {
    white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }
  .rv-discrete-color-legend-item.horizontal {
    display: inline-block; }
    .rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
      margin-left: 0;
      display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }
  .rv-discrete-color-legend-item.clickable:hover {
    background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }
  .rv-continuous-color-legend .rv-gradient {
    height: 4px;
    border-radius: 2px;
    margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }
  .rv-continuous-size-legend .rv-bubbles {
    text-align: justify;
    overflow: hidden;
    margin-bottom: 5px;
    width: 100%; }
  .rv-continuous-size-legend .rv-bubble {
    background: #d8d9dc;
    display: inline-block;
    vertical-align: bottom; }
  .rv-continuous-size-legend .rv-spacer {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left,
.rv-legend-titles__right,
.rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

body {
  font-family: Sintony, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal; }

h1 {
  font-size: 36px;
  margin: 20px 0; }

h2 {
  font-size: 24px;
  margin: 15px 0; }

main {
  padding: 40px 0; }

header {
  background: #f0f0f0;
  line-height: 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }

.flex {
  display: flex; }

.docs-link {
  font-weight: 500;
  font-size: 11px;
  margin-right: 5px;
  text-transform: uppercase;
  border-left: 1px solid #c0c0c0;
  padding-left: 5px;
  line-height: 1; }

.docs-link:first-child {
  border-left: 0px;
  padding-left: 0px; }

.docs-comment {
  display: flex;
  max-width: 300px; }

.header-contents {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px; }

.header-logo {
  color: #000;
  float: left;
  font-size: 20px;
  text-decoration: none; }

.background-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1; }

.dropdown-button {
  cursor: pointer;
  z-index: 10; }

.dropdown-wrapper {
  display: flex;
  position: relative; }
  .dropdown-wrapper .dropdown-inner-wrapper {
    background: #fff;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    height: auto;
    list-style: none;
    padding: 10px;
    position: absolute;
    right: -5px;
    top: 25px;
    width: 150px;
    z-index: 10; }
  .dropdown-wrapper a {
    display: flex;
    height: auto;
    line-height: 15px;
    text-decoration: none; }
  .dropdown-wrapper li {
    display: flex;
    height: 100%; }
  .dropdown-wrapper .subsection-label {
    font-weight: 600;
    line-height: 15px; }

article {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1200px;
  min-width: 650px;
  padding: 30px 20px 0; }
  article h1,
  article h2 {
    flex: 1 100%; }
    article h1 small,
    article h2 small {
      color: #6b6b76;
      font-size: 50%; }
  article section {
    flex-basis: 400px;
    flex-grow: 1;
    margin: 0 0 40px; }
  article .section-title {
    margin-bottom: 5px; }
  article .section-header {
    margin-bottom: 1em; }

.click-me {
  border: 0;
  background: #ef5d28;
  color: #fff;
  cursor: pointer;
  font-family: Sintony, Helvetica, sans-serif;
  font-size: 14px;
  outline: none;
  padding: 11px 20px;
  text-transform: uppercase;
  animation: shake 5s 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0); }
  .click-me:hover {
    background: #ff9833; }

@keyframes shake {
  1%,
  9% {
    transform: translate3d(-1px, 0, 0); }
  2%,
  8% {
    transform: translate3d(2px, 0, 0); }
  3%,
  5%,
  7% {
    transform: translate3d(-4px, 0, 0); }
  4%,
  6% {
    transform: translate3d(4px, 0, 0); } }

.example-with-click-me {
  position: relative;
  text-align: center;
  width: 100%; }
  .example-with-click-me:hover .click-me {
    animation: none; }
  .example-with-click-me .chart {
    margin-right: 200px; }
    .example-with-click-me .chart .rv-xy-plot__axis__tick__line {
      stroke: #6b6b76; }
  .example-with-click-me .legend {
    position: absolute;
    text-align: left;
    right: 0; }

.custom-hint {
  background: #f9e7bb;
  border-radius: 3px;
  border: 1px solid #edaf00;
  padding: 10px;
  color: #333;
  font-size: 10px;
  position: relative;
  margin: 12px 0 0 -10px; }
  .custom-hint::after {
    border-radius: 5px;
    border: 2px solid #edaf00;
    background: #fff;
    display: block;
    content: ' ';
    height: 6px;
    width: 6px;
    top: -17px;
    left: 5px;
    position: absolute; }

.complex-hint {
  margin-top: 40px; }
  .complex-hint .rv-hint {
    /* must be positioned in a parent with relative positioning */
    position: absolute;
    width: 0;
    height: 100%; }
    .complex-hint .rv-hint .hint--text-container {
      position: absolute;
      /*
       * set to 0,0 so that its content (including children)
       * can overflow out in vertical and horizontal
       */
      width: 0;
      height: 0;
      /*
       * use flex to place its children (centered) and aligned (bottom).
       * As its height is 0, align-items flex-end paints its items from cross-axis
       * up.  flex-start, its items would paint from cross-axis down.
       */
      display: flex;
      justify-content: center; }
      .complex-hint .rv-hint .hint--text-container.rightEdge-top {
        flex-direction: column-reverse;
        align-items: flex-start; }
      .complex-hint .rv-hint .hint--text-container.left-topEdge {
        flex-direction: row;
        align-items: flex-end; }
      .complex-hint .rv-hint .hint--text-container.left-bottomEdge {
        flex-direction: row;
        align-items: flex-start; }
      .complex-hint .rv-hint .hint--text-container.leftEdge-top {
        flex-direction: column;
        align-items: flex-end; }
      .complex-hint .rv-hint .hint--text-container .hint--text {
        /* text content uses -micro padding */
        padding: 4px;
        border: 2px solid black;
        color: black;
        white-space: nowrap; }
    .complex-hint .rv-hint .hint--pole {
      position: absolute; }
      .complex-hint .rv-hint .hint--pole.rightEdge-top {
        top: -1px;
        left: -10px;
        border-top: 2px solid black;
        width: 10px;
        height: 0; }
      .complex-hint .rv-hint .hint--pole.left-topEdge {
        border-left: 2px solid black;
        left: -1px;
        height: 10px;
        width: 0;
        top: 0; }
      .complex-hint .rv-hint .hint--pole.left-bottomEdge {
        border-left: 2px solid black;
        left: -1px;
        height: 25px;
        width: 0;
        top: -25px; }
      .complex-hint .rv-hint .hint--pole.leftEdge-top {
        top: -1px;
        border-top: 2px solid black;
        width: 30px;
        height: 0; }
  .complex-hint .rv-hint--horizontalAlign-rightEdge.rv-hint--verticalAlign-top {
    width: 0;
    height: 0; }
  .complex-hint .rv-hint--horizontalAlign-left.rv-hint--verticalAlign-topEdge {
    width: 0;
    height: 100%; }
  .complex-hint .rv-hint--horizontalAlign-left.rv-hint--verticalAlign-bottomEdge {
    width: 0;
    height: 0; }
  .complex-hint .rv-hint--horizontalAlign-leftEdge.rv-hint--verticalAlign-top {
    width: 100%;
    height: 0; }

.centered-and-flexed {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px; }
  .centered-and-flexed .centered-and-flexed-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 75%; }

.dynamic-treemap-example .rv-treemap__leaf--circle {
  border: thin solid white; }

.clustered-stacked-bar-chart-example .rv-discrete-color-legend {
  left: 40px;
  position: absolute;
  top: 0; }

.basic-sunburst-example-path-name {
  height: 20px; }

.showcase-button {
  background: #fff;
  border: thin solid #333;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 10px; }

.donut-chart-example .rv-radial-chart__series--pie__slice:hover {
  stroke: #000 !important;
  stroke-width: 2px !important; }

.parallel-coordinates-example .rv-xy-plot__series--line {
  stroke: #12939A !important; }
  .parallel-coordinates-example .rv-xy-plot__series--line:hover {
    stroke: #F15C17 !important; }

.canvas-example-controls {
  display: flex; }

.canvas-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%; }

.highlight-container {
  cursor: crosshair; }

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@font-face {
  font-family: Linearicons;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Linearicons.woff");
  src: url("../fonts/Linearicons.eot#iefix") format(embedded-opentype), url("../fonts/Linearicons.ttf") format(truetype), url("../fonts/Linearicons.eot") format(eot), url("../fonts/Linearicons.svg#Linearicons") format(svg); }

[class^='lnr-'],
[class*=' lnr-'] {
  font-family: 'Linearicons' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lnr-home:before {
  content: '\e600'; }

.lnr-home2:before {
  content: '\e601'; }

.lnr-home3:before {
  content: '\e602'; }

.lnr-home4:before {
  content: '\e603'; }

.lnr-home5:before {
  content: '\e604'; }

.lnr-home6:before {
  content: '\e605'; }

.lnr-bathtub:before {
  content: '\e606'; }

.lnr-toothbrush:before {
  content: '\e607'; }

.lnr-bed:before {
  content: '\e608'; }

.lnr-couch:before {
  content: '\e609'; }

.lnr-chair:before {
  content: '\e60a'; }

.lnr-city:before {
  content: '\e60b'; }

.lnr-apartment:before {
  content: '\e60c'; }

.lnr-pencil:before {
  content: '\e60d'; }

.lnr-pencil2:before {
  content: '\e60e'; }

.lnr-pen:before {
  content: '\e60f'; }

.lnr-pencil3:before {
  content: '\e610'; }

.lnr-eraser:before {
  content: '\e611'; }

.lnr-pencil4:before {
  content: '\e612'; }

.lnr-pencil5:before {
  content: '\e613'; }

.lnr-feather:before {
  content: '\e614'; }

.lnr-feather2:before {
  content: '\e615'; }

.lnr-feather3:before {
  content: '\e616'; }

.lnr-pen2:before {
  content: '\e617'; }

.lnr-pen-add:before {
  content: '\e618'; }

.lnr-pen-remove:before {
  content: '\e619'; }

.lnr-vector:before {
  content: '\e61a'; }

.lnr-pen3:before {
  content: '\e61b'; }

.lnr-blog:before {
  content: '\e61c'; }

.lnr-brush:before {
  content: '\e61d'; }

.lnr-brush2:before {
  content: '\e61e'; }

.lnr-spray:before {
  content: '\e61f'; }

.lnr-paint-roller:before {
  content: '\e620'; }

.lnr-stamp:before {
  content: '\e621'; }

.lnr-tape:before {
  content: '\e622'; }

.lnr-desk-tape:before {
  content: '\e623'; }

.lnr-texture:before {
  content: '\e624'; }

.lnr-eye-dropper:before {
  content: '\e625'; }

.lnr-palette:before {
  content: '\e626'; }

.lnr-color-sampler:before {
  content: '\e627'; }

.lnr-bucket:before {
  content: '\e628'; }

.lnr-gradient:before {
  content: '\e629'; }

.lnr-gradient2:before {
  content: '\e62a'; }

.lnr-magic-wand:before {
  content: '\e62b'; }

.lnr-magnet:before {
  content: '\e62c'; }

.lnr-pencil-ruler:before {
  content: '\e62d'; }

.lnr-pencil-ruler2:before {
  content: '\e62e'; }

.lnr-compass:before {
  content: '\e62f'; }

.lnr-aim:before {
  content: '\e630'; }

.lnr-gun:before {
  content: '\e631'; }

.lnr-bottle:before {
  content: '\e632'; }

.lnr-drop:before {
  content: '\e633'; }

.lnr-drop-crossed:before {
  content: '\e634'; }

.lnr-drop2:before {
  content: '\e635'; }

.lnr-snow:before {
  content: '\e636'; }

.lnr-snow2:before {
  content: '\e637'; }

.lnr-fire:before {
  content: '\e638'; }

.lnr-lighter:before {
  content: '\e639'; }

.lnr-knife:before {
  content: '\e63a'; }

.lnr-dagger:before {
  content: '\e63b'; }

.lnr-tissue:before {
  content: '\e63c'; }

.lnr-toilet-paper:before {
  content: '\e63d'; }

.lnr-poop:before {
  content: '\e63e'; }

.lnr-umbrella:before {
  content: '\e63f'; }

.lnr-umbrella2:before {
  content: '\e640'; }

.lnr-rain:before {
  content: '\e641'; }

.lnr-tornado:before {
  content: '\e642'; }

.lnr-wind:before {
  content: '\e643'; }

.lnr-fan:before {
  content: '\e644'; }

.lnr-contrast:before {
  content: '\e645'; }

.lnr-sun-small:before {
  content: '\e646'; }

.lnr-sun:before {
  content: '\e647'; }

.lnr-sun2:before {
  content: '\e648'; }

.lnr-moon:before {
  content: '\e649'; }

.lnr-cloud:before {
  content: '\e64a'; }

.lnr-cloud-upload:before {
  content: '\e64b'; }

.lnr-cloud-download:before {
  content: '\e64c'; }

.lnr-cloud-rain:before {
  content: '\e64d'; }

.lnr-cloud-hailstones:before {
  content: '\e64e'; }

.lnr-cloud-snow:before {
  content: '\e64f'; }

.lnr-cloud-windy:before {
  content: '\e650'; }

.lnr-sun-wind:before {
  content: '\e651'; }

.lnr-cloud-fog:before {
  content: '\e652'; }

.lnr-cloud-sun:before {
  content: '\e653'; }

.lnr-cloud-lightning:before {
  content: '\e654'; }

.lnr-cloud-sync:before {
  content: '\e655'; }

.lnr-cloud-lock:before {
  content: '\e656'; }

.lnr-cloud-gear:before {
  content: '\e657'; }

.lnr-cloud-alert:before {
  content: '\e658'; }

.lnr-cloud-check:before {
  content: '\e659'; }

.lnr-cloud-cross:before {
  content: '\e65a'; }

.lnr-cloud-crossed:before {
  content: '\e65b'; }

.lnr-cloud-database:before {
  content: '\e65c'; }

.lnr-database:before {
  content: '\e65d'; }

.lnr-database-add:before {
  content: '\e65e'; }

.lnr-database-remove:before {
  content: '\e65f'; }

.lnr-database-lock:before {
  content: '\e660'; }

.lnr-database-refresh:before {
  content: '\e661'; }

.lnr-database-check:before {
  content: '\e662'; }

.lnr-database-history:before {
  content: '\e663'; }

.lnr-database-upload:before {
  content: '\e664'; }

.lnr-database-download:before {
  content: '\e665'; }

.lnr-server:before {
  content: '\e666'; }

.lnr-shield:before {
  content: '\e667'; }

.lnr-shield-check:before {
  content: '\e668'; }

.lnr-shield-alert:before {
  content: '\e669'; }

.lnr-shield-cross:before {
  content: '\e66a'; }

.lnr-lock:before {
  content: '\e66b'; }

.lnr-rotation-lock:before {
  content: '\e66c'; }

.lnr-unlock:before {
  content: '\e66d'; }

.lnr-key:before {
  content: '\e66e'; }

.lnr-key-hole:before {
  content: '\e66f'; }

.lnr-toggle-off:before {
  content: '\e670'; }

.lnr-toggle-on:before {
  content: '\e671'; }

.lnr-cog:before {
  content: '\e672'; }

.lnr-cog2:before {
  content: '\e673'; }

.lnr-wrench:before {
  content: '\e674'; }

.lnr-screwdriver:before {
  content: '\e675'; }

.lnr-hammer-wrench:before {
  content: '\e676'; }

.lnr-hammer:before {
  content: '\e677'; }

.lnr-saw:before {
  content: '\e678'; }

.lnr-axe:before {
  content: '\e679'; }

.lnr-axe2:before {
  content: '\e67a'; }

.lnr-shovel:before {
  content: '\e67b'; }

.lnr-pickaxe:before {
  content: '\e67c'; }

.lnr-factory:before {
  content: '\e67d'; }

.lnr-factory2:before {
  content: '\e67e'; }

.lnr-recycle:before {
  content: '\e67f'; }

.lnr-trash:before {
  content: '\e680'; }

.lnr-trash2:before {
  content: '\e681'; }

.lnr-trash3:before {
  content: '\e682'; }

.lnr-broom:before {
  content: '\e683'; }

.lnr-game:before {
  content: '\e684'; }

.lnr-gamepad:before {
  content: '\e685'; }

.lnr-joystick:before {
  content: '\e686'; }

.lnr-dice:before {
  content: '\e687'; }

.lnr-spades:before {
  content: '\e688'; }

.lnr-diamonds:before {
  content: '\e689'; }

.lnr-clubs:before {
  content: '\e68a'; }

.lnr-hearts:before {
  content: '\e68b'; }

.lnr-heart:before {
  content: '\e68c'; }

.lnr-star:before {
  content: '\e68d'; }

.lnr-star-half:before {
  content: '\e68e'; }

.lnr-star-empty:before {
  content: '\e68f'; }

.lnr-flag:before {
  content: '\e690'; }

.lnr-flag2:before {
  content: '\e691'; }

.lnr-flag3:before {
  content: '\e692'; }

.lnr-mailbox-full:before {
  content: '\e693'; }

.lnr-mailbox-empty:before {
  content: '\e694'; }

.lnr-at-sign:before {
  content: '\e695'; }

.lnr-envelope:before {
  content: '\e696'; }

.lnr-envelope-open:before {
  content: '\e697'; }

.lnr-paperclip:before {
  content: '\e698'; }

.lnr-paper-plane:before {
  content: '\e699'; }

.lnr-reply:before {
  content: '\e69a'; }

.lnr-reply-all:before {
  content: '\e69b'; }

.lnr-inbox:before {
  content: '\e69c'; }

.lnr-inbox2:before {
  content: '\e69d'; }

.lnr-outbox:before {
  content: '\e69e'; }

.lnr-box:before {
  content: '\e69f'; }

.lnr-archive:before {
  content: '\e6a0'; }

.lnr-archive2:before {
  content: '\e6a1'; }

.lnr-drawers:before {
  content: '\e6a2'; }

.lnr-drawers2:before {
  content: '\e6a3'; }

.lnr-drawers3:before {
  content: '\e6a4'; }

.lnr-eye:before {
  content: '\e6a5'; }

.lnr-eye-crossed:before {
  content: '\e6a6'; }

.lnr-eye-plus:before {
  content: '\e6a7'; }

.lnr-eye-minus:before {
  content: '\e6a8'; }

.lnr-binoculars:before {
  content: '\e6a9'; }

.lnr-binoculars2:before {
  content: '\e6aa'; }

.lnr-hdd:before {
  content: '\e6ab'; }

.lnr-hdd-down:before {
  content: '\e6ac'; }

.lnr-hdd-up:before {
  content: '\e6ad'; }

.lnr-floppy-disk:before {
  content: '\e6ae'; }

.lnr-disc:before {
  content: '\e6af'; }

.lnr-tape2:before {
  content: '\e6b0'; }

.lnr-printer:before {
  content: '\e6b1'; }

.lnr-shredder:before {
  content: '\e6b2'; }

.lnr-file-empty:before {
  content: '\e6b3'; }

.lnr-file-add:before {
  content: '\e6b4'; }

.lnr-file-check:before {
  content: '\e6b5'; }

.lnr-file-lock:before {
  content: '\e6b6'; }

.lnr-files:before {
  content: '\e6b7'; }

.lnr-copy:before {
  content: '\e6b8'; }

.lnr-compare:before {
  content: '\e6b9'; }

.lnr-folder:before {
  content: '\e6ba'; }

.lnr-folder-search:before {
  content: '\e6bb'; }

.lnr-folder-plus:before {
  content: '\e6bc'; }

.lnr-folder-minus:before {
  content: '\e6bd'; }

.lnr-folder-download:before {
  content: '\e6be'; }

.lnr-folder-upload:before {
  content: '\e6bf'; }

.lnr-folder-star:before {
  content: '\e6c0'; }

.lnr-folder-heart:before {
  content: '\e6c1'; }

.lnr-folder-user:before {
  content: '\e6c2'; }

.lnr-folder-shared:before {
  content: '\e6c3'; }

.lnr-folder-music:before {
  content: '\e6c4'; }

.lnr-folder-picture:before {
  content: '\e6c5'; }

.lnr-folder-film:before {
  content: '\e6c6'; }

.lnr-scissors:before {
  content: '\e6c7'; }

.lnr-paste:before {
  content: '\e6c8'; }

.lnr-clipboard-empty:before {
  content: '\e6c9'; }

.lnr-clipboard-pencil:before {
  content: '\e6ca'; }

.lnr-clipboard-text:before {
  content: '\e6cb'; }

.lnr-clipboard-check:before {
  content: '\e6cc'; }

.lnr-clipboard-down:before {
  content: '\e6cd'; }

.lnr-clipboard-left:before {
  content: '\e6ce'; }

.lnr-clipboard-alert:before {
  content: '\e6cf'; }

.lnr-clipboard-user:before {
  content: '\e6d0'; }

.lnr-register:before {
  content: '\e6d1'; }

.lnr-enter:before {
  content: '\e6d2'; }

.lnr-exit:before {
  content: '\e6d3'; }

.lnr-papers:before {
  content: '\e6d4'; }

.lnr-news:before {
  content: '\e6d5'; }

.lnr-reading:before {
  content: '\e6d6'; }

.lnr-typewriter:before {
  content: '\e6d7'; }

.lnr-document:before {
  content: '\e6d8'; }

.lnr-document2:before {
  content: '\e6d9'; }

.lnr-graduation-hat:before {
  content: '\e6da'; }

.lnr-license:before {
  content: '\e6db'; }

.lnr-license2:before {
  content: '\e6dc'; }

.lnr-medal-empty:before {
  content: '\e6dd'; }

.lnr-medal-first:before {
  content: '\e6de'; }

.lnr-medal-second:before {
  content: '\e6df'; }

.lnr-medal-third:before {
  content: '\e6e0'; }

.lnr-podium:before {
  content: '\e6e1'; }

.lnr-trophy:before {
  content: '\e6e2'; }

.lnr-trophy2:before {
  content: '\e6e3'; }

.lnr-music-note:before {
  content: '\e6e4'; }

.lnr-music-note2:before {
  content: '\e6e5'; }

.lnr-music-note3:before {
  content: '\e6e6'; }

.lnr-playlist:before {
  content: '\e6e7'; }

.lnr-playlist-add:before {
  content: '\e6e8'; }

.lnr-guitar:before {
  content: '\e6e9'; }

.lnr-trumpet:before {
  content: '\e6ea'; }

.lnr-album:before {
  content: '\e6eb'; }

.lnr-shuffle:before {
  content: '\e6ec'; }

.lnr-repeat-one:before {
  content: '\e6ed'; }

.lnr-repeat:before {
  content: '\e6ee'; }

.lnr-headphones:before {
  content: '\e6ef'; }

.lnr-headset:before {
  content: '\e6f0'; }

.lnr-loudspeaker:before {
  content: '\e6f1'; }

.lnr-equalizer:before {
  content: '\e6f2'; }

.lnr-theater:before {
  content: '\e6f3'; }

.lnr-3d-glasses:before {
  content: '\e6f4'; }

.lnr-ticket:before {
  content: '\e6f5'; }

.lnr-presentation:before {
  content: '\e6f6'; }

.lnr-play:before {
  content: '\e6f7'; }

.lnr-film-play:before {
  content: '\e6f8'; }

.lnr-clapboard-play:before {
  content: '\e6f9'; }

.lnr-media:before {
  content: '\e6fa'; }

.lnr-film:before {
  content: '\e6fb'; }

.lnr-film2:before {
  content: '\e6fc'; }

.lnr-surveillance:before {
  content: '\e6fd'; }

.lnr-surveillance2:before {
  content: '\e6fe'; }

.lnr-camera:before {
  content: '\e6ff'; }

.lnr-camera-crossed:before {
  content: '\e700'; }

.lnr-camera-play:before {
  content: '\e701'; }

.lnr-time-lapse:before {
  content: '\e702'; }

.lnr-record:before {
  content: '\e703'; }

.lnr-camera2:before {
  content: '\e704'; }

.lnr-camera-flip:before {
  content: '\e705'; }

.lnr-panorama:before {
  content: '\e706'; }

.lnr-time-lapse2:before {
  content: '\e707'; }

.lnr-shutter:before {
  content: '\e708'; }

.lnr-shutter2:before {
  content: '\e709'; }

.lnr-face-detection:before {
  content: '\e70a'; }

.lnr-flare:before {
  content: '\e70b'; }

.lnr-convex:before {
  content: '\e70c'; }

.lnr-concave:before {
  content: '\e70d'; }

.lnr-picture:before {
  content: '\e70e'; }

.lnr-picture2:before {
  content: '\e70f'; }

.lnr-picture3:before {
  content: '\e710'; }

.lnr-pictures:before {
  content: '\e711'; }

.lnr-book:before {
  content: '\e712'; }

.lnr-audio-book:before {
  content: '\e713'; }

.lnr-book2:before {
  content: '\e714'; }

.lnr-bookmark:before {
  content: '\e715'; }

.lnr-bookmark2:before {
  content: '\e716'; }

.lnr-label:before {
  content: '\e717'; }

.lnr-library:before {
  content: '\e718'; }

.lnr-library2:before {
  content: '\e719'; }

.lnr-contacts:before {
  content: '\e71a'; }

.lnr-profile:before {
  content: '\e71b'; }

.lnr-portrait:before {
  content: '\e71c'; }

.lnr-portrait2:before {
  content: '\e71d'; }

.lnr-user:before {
  content: '\e71e'; }

.lnr-user-plus:before {
  content: '\e71f'; }

.lnr-user-minus:before {
  content: '\e720'; }

.lnr-user-lock:before {
  content: '\e721'; }

.lnr-users:before {
  content: '\e722'; }

.lnr-users2:before {
  content: '\e723'; }

.lnr-users-plus:before {
  content: '\e724'; }

.lnr-users-minus:before {
  content: '\e725'; }

.lnr-group-work:before {
  content: '\e726'; }

.lnr-woman:before {
  content: '\e727'; }

.lnr-man:before {
  content: '\e728'; }

.lnr-baby:before {
  content: '\e729'; }

.lnr-baby2:before {
  content: '\e72a'; }

.lnr-baby3:before {
  content: '\e72b'; }

.lnr-baby-bottle:before {
  content: '\e72c'; }

.lnr-walk:before {
  content: '\e72d'; }

.lnr-hand-waving:before {
  content: '\e72e'; }

.lnr-jump:before {
  content: '\e72f'; }

.lnr-run:before {
  content: '\e730'; }

.lnr-woman2:before {
  content: '\e731'; }

.lnr-man2:before {
  content: '\e732'; }

.lnr-man-woman:before {
  content: '\e733'; }

.lnr-height:before {
  content: '\e734'; }

.lnr-weight:before {
  content: '\e735'; }

.lnr-scale:before {
  content: '\e736'; }

.lnr-button:before {
  content: '\e737'; }

.lnr-bow-tie:before {
  content: '\e738'; }

.lnr-tie:before {
  content: '\e739'; }

.lnr-socks:before {
  content: '\e73a'; }

.lnr-shoe:before {
  content: '\e73b'; }

.lnr-shoes:before {
  content: '\e73c'; }

.lnr-hat:before {
  content: '\e73d'; }

.lnr-pants:before {
  content: '\e73e'; }

.lnr-shorts:before {
  content: '\e73f'; }

.lnr-flip-flops:before {
  content: '\e740'; }

.lnr-shirt:before {
  content: '\e741'; }

.lnr-hanger:before {
  content: '\e742'; }

.lnr-laundry:before {
  content: '\e743'; }

.lnr-store:before {
  content: '\e744'; }

.lnr-haircut:before {
  content: '\e745'; }

.lnr-store-24:before {
  content: '\e746'; }

.lnr-barcode:before {
  content: '\e747'; }

.lnr-barcode2:before {
  content: '\e748'; }

.lnr-barcode3:before {
  content: '\e749'; }

.lnr-cashier:before {
  content: '\e74a'; }

.lnr-bag:before {
  content: '\e74b'; }

.lnr-bag2:before {
  content: '\e74c'; }

.lnr-cart:before {
  content: '\e74d'; }

.lnr-cart-empty:before {
  content: '\e74e'; }

.lnr-cart-full:before {
  content: '\e74f'; }

.lnr-cart-plus:before {
  content: '\e750'; }

.lnr-cart-plus2:before {
  content: '\e751'; }

.lnr-cart-add:before {
  content: '\e752'; }

.lnr-cart-remove:before {
  content: '\e753'; }

.lnr-cart-exchange:before {
  content: '\e754'; }

.lnr-tag:before {
  content: '\e755'; }

.lnr-tags:before {
  content: '\e756'; }

.lnr-receipt:before {
  content: '\e757'; }

.lnr-wallet:before {
  content: '\e758'; }

.lnr-credit-card:before {
  content: '\e759'; }

.lnr-cash-dollar:before {
  content: '\e75a'; }

.lnr-cash-euro:before {
  content: '\e75b'; }

.lnr-cash-pound:before {
  content: '\e75c'; }

.lnr-cash-yen:before {
  content: '\e75d'; }

.lnr-bag-dollar:before {
  content: '\e75e'; }

.lnr-bag-euro:before {
  content: '\e75f'; }

.lnr-bag-pound:before {
  content: '\e760'; }

.lnr-bag-yen:before {
  content: '\e761'; }

.lnr-coin-dollar:before {
  content: '\e762'; }

.lnr-coin-euro:before {
  content: '\e763'; }

.lnr-coin-pound:before {
  content: '\e764'; }

.lnr-coin-yen:before {
  content: '\e765'; }

.lnr-calculator:before {
  content: '\e766'; }

.lnr-calculator2:before {
  content: '\e767'; }

.lnr-abacus:before {
  content: '\e768'; }

.lnr-vault:before {
  content: '\e769'; }

.lnr-telephone:before {
  content: '\e76a'; }

.lnr-phone-lock:before {
  content: '\e76b'; }

.lnr-phone-wave:before {
  content: '\e76c'; }

.lnr-phone-pause:before {
  content: '\e76d'; }

.lnr-phone-outgoing:before {
  content: '\e76e'; }

.lnr-phone-incoming:before {
  content: '\e76f'; }

.lnr-phone-in-out:before {
  content: '\e770'; }

.lnr-phone-error:before {
  content: '\e771'; }

.lnr-phone-sip:before {
  content: '\e772'; }

.lnr-phone-plus:before {
  content: '\e773'; }

.lnr-phone-minus:before {
  content: '\e774'; }

.lnr-voicemail:before {
  content: '\e775'; }

.lnr-dial:before {
  content: '\e776'; }

.lnr-telephone2:before {
  content: '\e777'; }

.lnr-pushpin:before {
  content: '\e778'; }

.lnr-pushpin2:before {
  content: '\e779'; }

.lnr-map-marker:before {
  content: '\e77a'; }

.lnr-map-marker-user:before {
  content: '\e77b'; }

.lnr-map-marker-down:before {
  content: '\e77c'; }

.lnr-map-marker-check:before {
  content: '\e77d'; }

.lnr-map-marker-crossed:before {
  content: '\e77e'; }

.lnr-radar:before {
  content: '\e77f'; }

.lnr-compass2:before {
  content: '\e780'; }

.lnr-map:before {
  content: '\e781'; }

.lnr-map2:before {
  content: '\e782'; }

.lnr-location:before {
  content: '\e783'; }

.lnr-road-sign:before {
  content: '\e784'; }

.lnr-calendar-empty:before {
  content: '\e785'; }

.lnr-calendar-check:before {
  content: '\e786'; }

.lnr-calendar-cross:before {
  content: '\e787'; }

.lnr-calendar-31:before {
  content: '\e788'; }

.lnr-calendar-full:before {
  content: '\e789'; }

.lnr-calendar-insert:before {
  content: '\e78a'; }

.lnr-calendar-text:before {
  content: '\e78b'; }

.lnr-calendar-user:before {
  content: '\e78c'; }

.lnr-mouse:before {
  content: '\e78d'; }

.lnr-mouse-left:before {
  content: '\e78e'; }

.lnr-mouse-right:before {
  content: '\e78f'; }

.lnr-mouse-both:before {
  content: '\e790'; }

.lnr-keyboard:before {
  content: '\e791'; }

.lnr-keyboard-up:before {
  content: '\e792'; }

.lnr-keyboard-down:before {
  content: '\e793'; }

.lnr-delete:before {
  content: '\e794'; }

.lnr-spell-check:before {
  content: '\e795'; }

.lnr-escape:before {
  content: '\e796'; }

.lnr-enter2:before {
  content: '\e797'; }

.lnr-screen:before {
  content: '\e798'; }

.lnr-aspect-ratio:before {
  content: '\e799'; }

.lnr-signal:before {
  content: '\e79a'; }

.lnr-signal-lock:before {
  content: '\e79b'; }

.lnr-signal-80:before {
  content: '\e79c'; }

.lnr-signal-60:before {
  content: '\e79d'; }

.lnr-signal-40:before {
  content: '\e79e'; }

.lnr-signal-20:before {
  content: '\e79f'; }

.lnr-signal-0:before {
  content: '\e7a0'; }

.lnr-signal-blocked:before {
  content: '\e7a1'; }

.lnr-sim:before {
  content: '\e7a2'; }

.lnr-flash-memory:before {
  content: '\e7a3'; }

.lnr-usb-drive:before {
  content: '\e7a4'; }

.lnr-phone:before {
  content: '\e7a5'; }

.lnr-smartphone:before {
  content: '\e7a6'; }

.lnr-smartphone-notification:before {
  content: '\e7a7'; }

.lnr-smartphone-vibration:before {
  content: '\e7a8'; }

.lnr-smartphone-embed:before {
  content: '\e7a9'; }

.lnr-smartphone-waves:before {
  content: '\e7aa'; }

.lnr-tablet:before {
  content: '\e7ab'; }

.lnr-tablet2:before {
  content: '\e7ac'; }

.lnr-laptop:before {
  content: '\e7ad'; }

.lnr-laptop-phone:before {
  content: '\e7ae'; }

.lnr-desktop:before {
  content: '\e7af'; }

.lnr-launch:before {
  content: '\e7b0'; }

.lnr-new-tab:before {
  content: '\e7b1'; }

.lnr-window:before {
  content: '\e7b2'; }

.lnr-cable:before {
  content: '\e7b3'; }

.lnr-cable2:before {
  content: '\e7b4'; }

.lnr-tv:before {
  content: '\e7b5'; }

.lnr-radio:before {
  content: '\e7b6'; }

.lnr-remote-control:before {
  content: '\e7b7'; }

.lnr-power-switch:before {
  content: '\e7b8'; }

.lnr-power:before {
  content: '\e7b9'; }

.lnr-power-crossed:before {
  content: '\e7ba'; }

.lnr-flash-auto:before {
  content: '\e7bb'; }

.lnr-lamp:before {
  content: '\e7bc'; }

.lnr-flashlight:before {
  content: '\e7bd'; }

.lnr-lampshade:before {
  content: '\e7be'; }

.lnr-cord:before {
  content: '\e7bf'; }

.lnr-outlet:before {
  content: '\e7c0'; }

.lnr-battery-power:before {
  content: '\e7c1'; }

.lnr-battery-empty:before {
  content: '\e7c2'; }

.lnr-battery-alert:before {
  content: '\e7c3'; }

.lnr-battery-error:before {
  content: '\e7c4'; }

.lnr-battery-low1:before {
  content: '\e7c5'; }

.lnr-battery-low2:before {
  content: '\e7c6'; }

.lnr-battery-low3:before {
  content: '\e7c7'; }

.lnr-battery-mid1:before {
  content: '\e7c8'; }

.lnr-battery-mid2:before {
  content: '\e7c9'; }

.lnr-battery-mid3:before {
  content: '\e7ca'; }

.lnr-battery-full:before {
  content: '\e7cb'; }

.lnr-battery-charging:before {
  content: '\e7cc'; }

.lnr-battery-charging2:before {
  content: '\e7cd'; }

.lnr-battery-charging3:before {
  content: '\e7ce'; }

.lnr-battery-charging4:before {
  content: '\e7cf'; }

.lnr-battery-charging5:before {
  content: '\e7d0'; }

.lnr-battery-charging6:before {
  content: '\e7d1'; }

.lnr-battery-charging7:before {
  content: '\e7d2'; }

.lnr-chip:before {
  content: '\e7d3'; }

.lnr-chip-x64:before {
  content: '\e7d4'; }

.lnr-chip-x86:before {
  content: '\e7d5'; }

.lnr-bubble:before {
  content: '\e7d6'; }

.lnr-bubbles:before {
  content: '\e7d7'; }

.lnr-bubble-dots:before {
  content: '\e7d8'; }

.lnr-bubble-alert:before {
  content: '\e7d9'; }

.lnr-bubble-question:before {
  content: '\e7da'; }

.lnr-bubble-text:before {
  content: '\e7db'; }

.lnr-bubble-pencil:before {
  content: '\e7dc'; }

.lnr-bubble-picture:before {
  content: '\e7dd'; }

.lnr-bubble-video:before {
  content: '\e7de'; }

.lnr-bubble-user:before {
  content: '\e7df'; }

.lnr-bubble-quote:before {
  content: '\e7e0'; }

.lnr-bubble-heart:before {
  content: '\e7e1'; }

.lnr-bubble-emoticon:before {
  content: '\e7e2'; }

.lnr-bubble-attachment:before {
  content: '\e7e3'; }

.lnr-phone-bubble:before {
  content: '\e7e4'; }

.lnr-quote-open:before {
  content: '\e7e5'; }

.lnr-quote-close:before {
  content: '\e7e6'; }

.lnr-dna:before {
  content: '\e7e7'; }

.lnr-heart-pulse:before {
  content: '\e7e8'; }

.lnr-pulse:before {
  content: '\e7e9'; }

.lnr-syringe:before {
  content: '\e7ea'; }

.lnr-pills:before {
  content: '\e7eb'; }

.lnr-first-aid:before {
  content: '\e7ec'; }

.lnr-lifebuoy:before {
  content: '\e7ed'; }

.lnr-bandage:before {
  content: '\e7ee'; }

.lnr-bandages:before {
  content: '\e7ef'; }

.lnr-thermometer:before {
  content: '\e7f0'; }

.lnr-microscope:before {
  content: '\e7f1'; }

.lnr-brain:before {
  content: '\e7f2'; }

.lnr-beaker:before {
  content: '\e7f3'; }

.lnr-skull:before {
  content: '\e7f4'; }

.lnr-bone:before {
  content: '\e7f5'; }

.lnr-construction:before {
  content: '\e7f6'; }

.lnr-construction-cone:before {
  content: '\e7f7'; }

.lnr-pie-chart:before {
  content: '\e7f8'; }

.lnr-pie-chart2:before {
  content: '\e7f9'; }

.lnr-graph:before {
  content: '\e7fa'; }

.lnr-chart-growth:before {
  content: '\e7fb'; }

.lnr-chart-bars:before {
  content: '\e7fc'; }

.lnr-chart-settings:before {
  content: '\e7fd'; }

.lnr-cake:before {
  content: '\e7fe'; }

.lnr-gift:before {
  content: '\e7ff'; }

.lnr-balloon:before {
  content: '\e800'; }

.lnr-rank:before {
  content: '\e801'; }

.lnr-rank2:before {
  content: '\e802'; }

.lnr-rank3:before {
  content: '\e803'; }

.lnr-crown:before {
  content: '\e804'; }

.lnr-lotus:before {
  content: '\e805'; }

.lnr-diamond:before {
  content: '\e806'; }

.lnr-diamond2:before {
  content: '\e807'; }

.lnr-diamond3:before {
  content: '\e808'; }

.lnr-diamond4:before {
  content: '\e809'; }

.lnr-linearicons:before {
  content: '\e80a'; }

.lnr-teacup:before {
  content: '\e80b'; }

.lnr-teapot:before {
  content: '\e80c'; }

.lnr-glass:before {
  content: '\e80d'; }

.lnr-bottle2:before {
  content: '\e80e'; }

.lnr-glass-cocktail:before {
  content: '\e80f'; }

.lnr-glass2:before {
  content: '\e810'; }

.lnr-dinner:before {
  content: '\e811'; }

.lnr-dinner2:before {
  content: '\e812'; }

.lnr-chef:before {
  content: '\e813'; }

.lnr-scale2:before {
  content: '\e814'; }

.lnr-egg:before {
  content: '\e815'; }

.lnr-egg2:before {
  content: '\e816'; }

.lnr-eggs:before {
  content: '\e817'; }

.lnr-platter:before {
  content: '\e818'; }

.lnr-steak:before {
  content: '\e819'; }

.lnr-hamburger:before {
  content: '\e81a'; }

.lnr-hotdog:before {
  content: '\e81b'; }

.lnr-pizza:before {
  content: '\e81c'; }

.lnr-sausage:before {
  content: '\e81d'; }

.lnr-chicken:before {
  content: '\e81e'; }

.lnr-fish:before {
  content: '\e81f'; }

.lnr-carrot:before {
  content: '\e820'; }

.lnr-cheese:before {
  content: '\e821'; }

.lnr-bread:before {
  content: '\e822'; }

.lnr-ice-cream:before {
  content: '\e823'; }

.lnr-ice-cream2:before {
  content: '\e824'; }

.lnr-candy:before {
  content: '\e825'; }

.lnr-lollipop:before {
  content: '\e826'; }

.lnr-coffee-bean:before {
  content: '\e827'; }

.lnr-coffee-cup:before {
  content: '\e828'; }

.lnr-cherry:before {
  content: '\e829'; }

.lnr-grapes:before {
  content: '\e82a'; }

.lnr-citrus:before {
  content: '\e82b'; }

.lnr-apple:before {
  content: '\e82c'; }

.lnr-leaf:before {
  content: '\e82d'; }

.lnr-landscape:before {
  content: '\e82e'; }

.lnr-pine-tree:before {
  content: '\e82f'; }

.lnr-tree:before {
  content: '\e830'; }

.lnr-cactus:before {
  content: '\e831'; }

.lnr-paw:before {
  content: '\e832'; }

.lnr-footprint:before {
  content: '\e833'; }

.lnr-speed-slow:before {
  content: '\e834'; }

.lnr-speed-medium:before {
  content: '\e835'; }

.lnr-speed-fast:before {
  content: '\e836'; }

.lnr-rocket:before {
  content: '\e837'; }

.lnr-hammer2:before {
  content: '\e838'; }

.lnr-balance:before {
  content: '\e839'; }

.lnr-briefcase:before {
  content: '\e83a'; }

.lnr-luggage-weight:before {
  content: '\e83b'; }

.lnr-dolly:before {
  content: '\e83c'; }

.lnr-plane:before {
  content: '\e83d'; }

.lnr-plane-crossed:before {
  content: '\e83e'; }

.lnr-helicopter:before {
  content: '\e83f'; }

.lnr-traffic-lights:before {
  content: '\e840'; }

.lnr-siren:before {
  content: '\e841'; }

.lnr-road:before {
  content: '\e842'; }

.lnr-engine:before {
  content: '\e843'; }

.lnr-oil-pressure:before {
  content: '\e844'; }

.lnr-coolant-temperature:before {
  content: '\e845'; }

.lnr-car-battery:before {
  content: '\e846'; }

.lnr-gas:before {
  content: '\e847'; }

.lnr-gallon:before {
  content: '\e848'; }

.lnr-transmission:before {
  content: '\e849'; }

.lnr-car:before {
  content: '\e84a'; }

.lnr-car-wash:before {
  content: '\e84b'; }

.lnr-car-wash2:before {
  content: '\e84c'; }

.lnr-bus:before {
  content: '\e84d'; }

.lnr-bus2:before {
  content: '\e84e'; }

.lnr-car2:before {
  content: '\e84f'; }

.lnr-parking:before {
  content: '\e850'; }

.lnr-car-lock:before {
  content: '\e851'; }

.lnr-taxi:before {
  content: '\e852'; }

.lnr-car-siren:before {
  content: '\e853'; }

.lnr-car-wash3:before {
  content: '\e854'; }

.lnr-car-wash4:before {
  content: '\e855'; }

.lnr-ambulance:before {
  content: '\e856'; }

.lnr-truck:before {
  content: '\e857'; }

.lnr-trailer:before {
  content: '\e858'; }

.lnr-scale-truck:before {
  content: '\e859'; }

.lnr-train:before {
  content: '\e85a'; }

.lnr-ship:before {
  content: '\e85b'; }

.lnr-ship2:before {
  content: '\e85c'; }

.lnr-anchor:before {
  content: '\e85d'; }

.lnr-boat:before {
  content: '\e85e'; }

.lnr-bicycle:before {
  content: '\e85f'; }

.lnr-bicycle2:before {
  content: '\e860'; }

.lnr-dumbbell:before {
  content: '\e861'; }

.lnr-bench-press:before {
  content: '\e862'; }

.lnr-swim:before {
  content: '\e863'; }

.lnr-football:before {
  content: '\e864'; }

.lnr-baseball-bat:before {
  content: '\e865'; }

.lnr-baseball:before {
  content: '\e866'; }

.lnr-tennis:before {
  content: '\e867'; }

.lnr-tennis2:before {
  content: '\e868'; }

.lnr-ping-pong:before {
  content: '\e869'; }

.lnr-hockey:before {
  content: '\e86a'; }

.lnr-8ball:before {
  content: '\e86b'; }

.lnr-bowling:before {
  content: '\e86c'; }

.lnr-bowling-pins:before {
  content: '\e86d'; }

.lnr-golf:before {
  content: '\e86e'; }

.lnr-golf2:before {
  content: '\e86f'; }

.lnr-archery:before {
  content: '\e870'; }

.lnr-slingshot:before {
  content: '\e871'; }

.lnr-soccer:before {
  content: '\e872'; }

.lnr-basketball:before {
  content: '\e873'; }

.lnr-cube:before {
  content: '\e874'; }

.lnr-3d-rotate:before {
  content: '\e875'; }

.lnr-puzzle:before {
  content: '\e876'; }

.lnr-glasses:before {
  content: '\e877'; }

.lnr-glasses2:before {
  content: '\e878'; }

.lnr-accessibility:before {
  content: '\e879'; }

.lnr-wheelchair:before {
  content: '\e87a'; }

.lnr-wall:before {
  content: '\e87b'; }

.lnr-fence:before {
  content: '\e87c'; }

.lnr-wall2:before {
  content: '\e87d'; }

.lnr-icons:before {
  content: '\e87e'; }

.lnr-resize-handle:before {
  content: '\e87f'; }

.lnr-icons2:before {
  content: '\e880'; }

.lnr-select:before {
  content: '\e881'; }

.lnr-select2:before {
  content: '\e882'; }

.lnr-site-map:before {
  content: '\e883'; }

.lnr-earth:before {
  content: '\e884'; }

.lnr-earth-lock:before {
  content: '\e885'; }

.lnr-network:before {
  content: '\e886'; }

.lnr-network-lock:before {
  content: '\e887'; }

.lnr-planet:before {
  content: '\e888'; }

.lnr-happy:before {
  content: '\e889'; }

.lnr-smile:before {
  content: '\e88a'; }

.lnr-grin:before {
  content: '\e88b'; }

.lnr-tongue:before {
  content: '\e88c'; }

.lnr-sad:before {
  content: '\e88d'; }

.lnr-wink:before {
  content: '\e88e'; }

.lnr-dream:before {
  content: '\e88f'; }

.lnr-shocked:before {
  content: '\e890'; }

.lnr-shocked2:before {
  content: '\e891'; }

.lnr-tongue2:before {
  content: '\e892'; }

.lnr-neutral:before {
  content: '\e893'; }

.lnr-happy-grin:before {
  content: '\e894'; }

.lnr-cool:before {
  content: '\e895'; }

.lnr-mad:before {
  content: '\e896'; }

.lnr-grin-evil:before {
  content: '\e897'; }

.lnr-evil:before {
  content: '\e898'; }

.lnr-wow:before {
  content: '\e899'; }

.lnr-annoyed:before {
  content: '\e89a'; }

.lnr-wondering:before {
  content: '\e89b'; }

.lnr-confused:before {
  content: '\e89c'; }

.lnr-zipped:before {
  content: '\e89d'; }

.lnr-grumpy:before {
  content: '\e89e'; }

.lnr-mustache:before {
  content: '\e89f'; }

.lnr-tombstone-hipster:before {
  content: '\e8a0'; }

.lnr-tombstone:before {
  content: '\e8a1'; }

.lnr-ghost:before {
  content: '\e8a2'; }

.lnr-ghost-hipster:before {
  content: '\e8a3'; }

.lnr-halloween:before {
  content: '\e8a4'; }

.lnr-christmas:before {
  content: '\e8a5'; }

.lnr-easter-egg:before {
  content: '\e8a6'; }

.lnr-mustache2:before {
  content: '\e8a7'; }

.lnr-mustache-glasses:before {
  content: '\e8a8'; }

.lnr-pipe:before {
  content: '\e8a9'; }

.lnr-alarm:before {
  content: '\e8aa'; }

.lnr-alarm-add:before {
  content: '\e8ab'; }

.lnr-alarm-snooze:before {
  content: '\e8ac'; }

.lnr-alarm-ringing:before {
  content: '\e8ad'; }

.lnr-bullhorn:before {
  content: '\e8ae'; }

.lnr-hearing:before {
  content: '\e8af'; }

.lnr-volume-high:before {
  content: '\e8b0'; }

.lnr-volume-medium:before {
  content: '\e8b1'; }

.lnr-volume-low:before {
  content: '\e8b2'; }

.lnr-volume:before {
  content: '\e8b3'; }

.lnr-mute:before {
  content: '\e8b4'; }

.lnr-lan:before {
  content: '\e8b5'; }

.lnr-lan2:before {
  content: '\e8b6'; }

.lnr-wifi:before {
  content: '\e8b7'; }

.lnr-wifi-lock:before {
  content: '\e8b8'; }

.lnr-wifi-blocked:before {
  content: '\e8b9'; }

.lnr-wifi-mid:before {
  content: '\e8ba'; }

.lnr-wifi-low:before {
  content: '\e8bb'; }

.lnr-wifi-low2:before {
  content: '\e8bc'; }

.lnr-wifi-alert:before {
  content: '\e8bd'; }

.lnr-wifi-alert-mid:before {
  content: '\e8be'; }

.lnr-wifi-alert-low:before {
  content: '\e8bf'; }

.lnr-wifi-alert-low2:before {
  content: '\e8c0'; }

.lnr-stream:before {
  content: '\e8c1'; }

.lnr-stream-check:before {
  content: '\e8c2'; }

.lnr-stream-error:before {
  content: '\e8c3'; }

.lnr-stream-alert:before {
  content: '\e8c4'; }

.lnr-communication:before {
  content: '\e8c5'; }

.lnr-communication-crossed:before {
  content: '\e8c6'; }

.lnr-broadcast:before {
  content: '\e8c7'; }

.lnr-antenna:before {
  content: '\e8c8'; }

.lnr-satellite:before {
  content: '\e8c9'; }

.lnr-satellite2:before {
  content: '\e8ca'; }

.lnr-mic:before {
  content: '\e8cb'; }

.lnr-mic-mute:before {
  content: '\e8cc'; }

.lnr-mic2:before {
  content: '\e8cd'; }

.lnr-spotlights:before {
  content: '\e8ce'; }

.lnr-hourglass:before {
  content: '\e8cf'; }

.lnr-loading:before {
  content: '\e8d0'; }

.lnr-loading2:before {
  content: '\e8d1'; }

.lnr-loading3:before {
  content: '\e8d2'; }

.lnr-refresh:before {
  content: '\e8d3'; }

.lnr-refresh2:before {
  content: '\e8d4'; }

.lnr-undo:before {
  content: '\e8d5'; }

.lnr-redo:before {
  content: '\e8d6'; }

.lnr-jump2:before {
  content: '\e8d7'; }

.lnr-undo2:before {
  content: '\e8d8'; }

.lnr-redo2:before {
  content: '\e8d9'; }

.lnr-sync:before {
  content: '\e8da'; }

.lnr-repeat-one2:before {
  content: '\e8db'; }

.lnr-sync-crossed:before {
  content: '\e8dc'; }

.lnr-sync2:before {
  content: '\e8dd'; }

.lnr-repeat-one3:before {
  content: '\e8de'; }

.lnr-sync-crossed2:before {
  content: '\e8df'; }

.lnr-return:before {
  content: '\e8e0'; }

.lnr-return2:before {
  content: '\e8e1'; }

.lnr-refund:before {
  content: '\e8e2'; }

.lnr-history:before {
  content: '\e8e3'; }

.lnr-history2:before {
  content: '\e8e4'; }

.lnr-self-timer:before {
  content: '\e8e5'; }

.lnr-clock:before {
  content: '\e8e6'; }

.lnr-clock2:before {
  content: '\e8e7'; }

.lnr-clock3:before {
  content: '\e8e8'; }

.lnr-watch:before {
  content: '\e8e9'; }

.lnr-alarm2:before {
  content: '\e8ea'; }

.lnr-alarm-add2:before {
  content: '\e8eb'; }

.lnr-alarm-remove:before {
  content: '\e8ec'; }

.lnr-alarm-check:before {
  content: '\e8ed'; }

.lnr-alarm-error:before {
  content: '\e8ee'; }

.lnr-timer:before {
  content: '\e8ef'; }

.lnr-timer-crossed:before {
  content: '\e8f0'; }

.lnr-timer2:before {
  content: '\e8f1'; }

.lnr-timer-crossed2:before {
  content: '\e8f2'; }

.lnr-download:before {
  content: '\e8f3'; }

.lnr-upload:before {
  content: '\e8f4'; }

.lnr-download2:before {
  content: '\e8f5'; }

.lnr-upload2:before {
  content: '\e8f6'; }

.lnr-enter-up:before {
  content: '\e8f7'; }

.lnr-enter-down:before {
  content: '\e8f8'; }

.lnr-enter-left:before {
  content: '\e8f9'; }

.lnr-enter-right:before {
  content: '\e8fa'; }

.lnr-exit-up:before {
  content: '\e8fb'; }

.lnr-exit-down:before {
  content: '\e8fc'; }

.lnr-exit-left:before {
  content: '\e8fd'; }

.lnr-exit-right:before {
  content: '\e8fe'; }

.lnr-enter-up2:before {
  content: '\e8ff'; }

.lnr-enter-down2:before {
  content: '\e900'; }

.lnr-enter-vertical:before {
  content: '\e901'; }

.lnr-enter-left2:before {
  content: '\e902'; }

.lnr-enter-right2:before {
  content: '\e903'; }

.lnr-enter-horizontal:before {
  content: '\e904'; }

.lnr-exit-up2:before {
  content: '\e905'; }

.lnr-exit-down2:before {
  content: '\e906'; }

.lnr-exit-left2:before {
  content: '\e907'; }

.lnr-exit-right2:before {
  content: '\e908'; }

.lnr-cli:before {
  content: '\e909'; }

.lnr-bug:before {
  content: '\e90a'; }

.lnr-code:before {
  content: '\e90b'; }

.lnr-file-code:before {
  content: '\e90c'; }

.lnr-file-image:before {
  content: '\e90d'; }

.lnr-file-zip:before {
  content: '\e90e'; }

.lnr-file-audio:before {
  content: '\e90f'; }

.lnr-file-video:before {
  content: '\e910'; }

.lnr-file-preview:before {
  content: '\e911'; }

.lnr-file-charts:before {
  content: '\e912'; }

.lnr-file-stats:before {
  content: '\e913'; }

.lnr-file-spreadsheet:before {
  content: '\e914'; }

.lnr-link:before {
  content: '\e915'; }

.lnr-unlink:before {
  content: '\e916'; }

.lnr-link2:before {
  content: '\e917'; }

.lnr-unlink2:before {
  content: '\e918'; }

.lnr-thumbs-up:before {
  content: '\e919'; }

.lnr-thumbs-down:before {
  content: '\e91a'; }

.lnr-thumbs-up2:before {
  content: '\e91b'; }

.lnr-thumbs-down2:before {
  content: '\e91c'; }

.lnr-thumbs-up3:before {
  content: '\e91d'; }

.lnr-thumbs-down3:before {
  content: '\e91e'; }

.lnr-share:before {
  content: '\e91f'; }

.lnr-share2:before {
  content: '\e920'; }

.lnr-share3:before {
  content: '\e921'; }

.lnr-magnifier:before {
  content: '\e922'; }

.lnr-file-search:before {
  content: '\e923'; }

.lnr-find-replace:before {
  content: '\e924'; }

.lnr-zoom-in:before {
  content: '\e925'; }

.lnr-zoom-out:before {
  content: '\e926'; }

.lnr-loupe:before {
  content: '\e927'; }

.lnr-loupe-zoom-in:before {
  content: '\e928'; }

.lnr-loupe-zoom-out:before {
  content: '\e929'; }

.lnr-cross:before {
  content: '\e92a'; }

.lnr-menu:before {
  content: '\e92b'; }

.lnr-list:before {
  content: '\e92c'; }

.lnr-list2:before {
  content: '\e92d'; }

.lnr-list3:before {
  content: '\e92e'; }

.lnr-menu2:before {
  content: '\e92f'; }

.lnr-list4:before {
  content: '\e930'; }

.lnr-menu3:before {
  content: '\e931'; }

.lnr-exclamation:before {
  content: '\e932'; }

.lnr-question:before {
  content: '\e933'; }

.lnr-check:before {
  content: '\e934'; }

.lnr-cross2:before {
  content: '\e935'; }

.lnr-plus:before {
  content: '\e936'; }

.lnr-minus:before {
  content: '\e937'; }

.lnr-percent:before {
  content: '\e938'; }

.lnr-chevron-up:before {
  content: '\e939'; }

.lnr-chevron-down:before {
  content: '\e93a'; }

.lnr-chevron-left:before {
  content: '\e93b'; }

.lnr-chevron-right:before {
  content: '\e93c'; }

.lnr-chevrons-expand-vertical:before {
  content: '\e93d'; }

.lnr-chevrons-expand-horizontal:before {
  content: '\e93e'; }

.lnr-chevrons-contract-vertical:before {
  content: '\e93f'; }

.lnr-chevrons-contract-horizontal:before {
  content: '\e940'; }

.lnr-arrow-up:before {
  content: '\e941'; }

.lnr-arrow-down:before {
  content: '\e942'; }

.lnr-arrow-left:before {
  content: '\e943'; }

.lnr-arrow-right:before {
  content: '\e944'; }

.lnr-arrow-up-right:before {
  content: '\e945'; }

.lnr-arrows-merge:before {
  content: '\e946'; }

.lnr-arrows-split:before {
  content: '\e947'; }

.lnr-arrow-divert:before {
  content: '\e948'; }

.lnr-arrow-return:before {
  content: '\e949'; }

.lnr-expand:before {
  content: '\e94a'; }

.lnr-contract:before {
  content: '\e94b'; }

.lnr-expand2:before {
  content: '\e94c'; }

.lnr-contract2:before {
  content: '\e94d'; }

.lnr-move:before {
  content: '\e94e'; }

.lnr-tab:before {
  content: '\e94f'; }

.lnr-arrow-wave:before {
  content: '\e950'; }

.lnr-expand3:before {
  content: '\e951'; }

.lnr-expand4:before {
  content: '\e952'; }

.lnr-contract3:before {
  content: '\e953'; }

.lnr-notification:before {
  content: '\e954'; }

.lnr-warning:before {
  content: '\e955'; }

.lnr-notification-circle:before {
  content: '\e956'; }

.lnr-question-circle:before {
  content: '\e957'; }

.lnr-menu-circle:before {
  content: '\e958'; }

.lnr-checkmark-circle:before {
  content: '\e959'; }

.lnr-cross-circle:before {
  content: '\e95a'; }

.lnr-plus-circle:before {
  content: '\e95b'; }

.lnr-circle-minus:before {
  content: '\e95c'; }

.lnr-percent-circle:before {
  content: '\e95d'; }

.lnr-arrow-up-circle:before {
  content: '\e95e'; }

.lnr-arrow-down-circle:before {
  content: '\e95f'; }

.lnr-arrow-left-circle:before {
  content: '\e960'; }

.lnr-arrow-right-circle:before {
  content: '\e961'; }

.lnr-chevron-up-circle:before {
  content: '\e962'; }

.lnr-chevron-down-circle:before {
  content: '\e963'; }

.lnr-chevron-left-circle:before {
  content: '\e964'; }

.lnr-chevron-right-circle:before {
  content: '\e965'; }

.lnr-backward-circle:before {
  content: '\e966'; }

.lnr-first-circle:before {
  content: '\e967'; }

.lnr-previous-circle:before {
  content: '\e968'; }

.lnr-stop-circle:before {
  content: '\e969'; }

.lnr-play-circle:before {
  content: '\e96a'; }

.lnr-pause-circle:before {
  content: '\e96b'; }

.lnr-next-circle:before {
  content: '\e96c'; }

.lnr-last-circle:before {
  content: '\e96d'; }

.lnr-forward-circle:before {
  content: '\e96e'; }

.lnr-eject-circle:before {
  content: '\e96f'; }

.lnr-crop:before {
  content: '\e970'; }

.lnr-frame-expand:before {
  content: '\e971'; }

.lnr-frame-contract:before {
  content: '\e972'; }

.lnr-focus:before {
  content: '\e973'; }

.lnr-transform:before {
  content: '\e974'; }

.lnr-grid:before {
  content: '\e975'; }

.lnr-grid-crossed:before {
  content: '\e976'; }

.lnr-layers:before {
  content: '\e977'; }

.lnr-layers-crossed:before {
  content: '\e978'; }

.lnr-toggle:before {
  content: '\e979'; }

.lnr-rulers:before {
  content: '\e97a'; }

.lnr-ruler:before {
  content: '\e97b'; }

.lnr-funnel:before {
  content: '\e97c'; }

.lnr-flip-horizontal:before {
  content: '\e97d'; }

.lnr-flip-vertical:before {
  content: '\e97e'; }

.lnr-flip-horizontal2:before {
  content: '\e97f'; }

.lnr-flip-vertical2:before {
  content: '\e980'; }

.lnr-angle:before {
  content: '\e981'; }

.lnr-angle2:before {
  content: '\e982'; }

.lnr-subtract:before {
  content: '\e983'; }

.lnr-combine:before {
  content: '\e984'; }

.lnr-intersect:before {
  content: '\e985'; }

.lnr-exclude:before {
  content: '\e986'; }

.lnr-align-center-vertical:before {
  content: '\e987'; }

.lnr-align-right:before {
  content: '\e988'; }

.lnr-align-bottom:before {
  content: '\e989'; }

.lnr-align-left:before {
  content: '\e98a'; }

.lnr-align-center-horizontal:before {
  content: '\e98b'; }

.lnr-align-top:before {
  content: '\e98c'; }

.lnr-square:before {
  content: '\e98d'; }

.lnr-plus-square:before {
  content: '\e98e'; }

.lnr-minus-square:before {
  content: '\e98f'; }

.lnr-percent-square:before {
  content: '\e990'; }

.lnr-arrow-up-square:before {
  content: '\e991'; }

.lnr-arrow-down-square:before {
  content: '\e992'; }

.lnr-arrow-left-square:before {
  content: '\e993'; }

.lnr-arrow-right-square:before {
  content: '\e994'; }

.lnr-chevron-up-square:before {
  content: '\e995'; }

.lnr-chevron-down-square:before {
  content: '\e996'; }

.lnr-chevron-left-square:before {
  content: '\e997'; }

.lnr-chevron-right-square:before {
  content: '\e998'; }

.lnr-check-square:before {
  content: '\e999'; }

.lnr-cross-square:before {
  content: '\e99a'; }

.lnr-menu-square:before {
  content: '\e99b'; }

.lnr-prohibited:before {
  content: '\e99c'; }

.lnr-circle:before {
  content: '\e99d'; }

.lnr-radio-button:before {
  content: '\e99e'; }

.lnr-ligature:before {
  content: '\e99f'; }

.lnr-text-format:before {
  content: '\e9a0'; }

.lnr-text-format-remove:before {
  content: '\e9a1'; }

.lnr-text-size:before {
  content: '\e9a2'; }

.lnr-bold:before {
  content: '\e9a3'; }

.lnr-italic:before {
  content: '\e9a4'; }

.lnr-underline:before {
  content: '\e9a5'; }

.lnr-strikethrough:before {
  content: '\e9a6'; }

.lnr-highlight:before {
  content: '\e9a7'; }

.lnr-text-align-left:before {
  content: '\e9a8'; }

.lnr-text-align-center:before {
  content: '\e9a9'; }

.lnr-text-align-right:before {
  content: '\e9aa'; }

.lnr-text-align-justify:before {
  content: '\e9ab'; }

.lnr-line-spacing:before {
  content: '\e9ac'; }

.lnr-indent-increase:before {
  content: '\e9ad'; }

.lnr-indent-decrease:before {
  content: '\e9ae'; }

.lnr-text-wrap:before {
  content: '\e9af'; }

.lnr-pilcrow:before {
  content: '\e9b0'; }

.lnr-direction-ltr:before {
  content: '\e9b1'; }

.lnr-direction-rtl:before {
  content: '\e9b2'; }

.lnr-page-break:before {
  content: '\e9b3'; }

.lnr-page-break2:before {
  content: '\e9b4'; }

.lnr-sort-alpha-asc:before {
  content: '\e9b5'; }

.lnr-sort-alpha-desc:before {
  content: '\e9b6'; }

.lnr-sort-numeric-asc:before {
  content: '\e9b7'; }

.lnr-sort-numeric-desc:before {
  content: '\e9b8'; }

.lnr-sort-amount-asc:before {
  content: '\e9b9'; }

.lnr-sort-amount-desc:before {
  content: '\e9ba'; }

.lnr-sort-time-asc:before {
  content: '\e9bb'; }

.lnr-sort-time-desc:before {
  content: '\e9bc'; }

.lnr-sigma:before {
  content: '\e9bd'; }

.lnr-pencil-line:before {
  content: '\e9be'; }

.lnr-hand:before {
  content: '\e9bf'; }

.lnr-pointer-up:before {
  content: '\e9c0'; }

.lnr-pointer-right:before {
  content: '\e9c1'; }

.lnr-pointer-down:before {
  content: '\e9c2'; }

.lnr-pointer-left:before {
  content: '\e9c3'; }

.lnr-finger-tap:before {
  content: '\e9c4'; }

.lnr-fingers-tap:before {
  content: '\e9c5'; }

.lnr-reminder:before {
  content: '\e9c6'; }

.lnr-fingers-crossed:before {
  content: '\e9c7'; }

.lnr-fingers-victory:before {
  content: '\e9c8'; }

.lnr-gesture-zoom:before {
  content: '\e9c9'; }

.lnr-gesture-pinch:before {
  content: '\e9ca'; }

.lnr-fingers-scroll-horizontal:before {
  content: '\e9cb'; }

.lnr-fingers-scroll-vertical:before {
  content: '\e9cc'; }

.lnr-fingers-scroll-left:before {
  content: '\e9cd'; }

.lnr-fingers-scroll-right:before {
  content: '\e9ce'; }

.lnr-hand2:before {
  content: '\e9cf'; }

.lnr-pointer-up2:before {
  content: '\e9d0'; }

.lnr-pointer-right2:before {
  content: '\e9d1'; }

.lnr-pointer-down2:before {
  content: '\e9d2'; }

.lnr-pointer-left2:before {
  content: '\e9d3'; }

.lnr-finger-tap2:before {
  content: '\e9d4'; }

.lnr-fingers-tap2:before {
  content: '\e9d5'; }

.lnr-reminder2:before {
  content: '\e9d6'; }

.lnr-gesture-zoom2:before {
  content: '\e9d7'; }

.lnr-gesture-pinch2:before {
  content: '\e9d8'; }

.lnr-fingers-scroll-horizontal2:before {
  content: '\e9d9'; }

.lnr-fingers-scroll-vertical2:before {
  content: '\e9da'; }

.lnr-fingers-scroll-left2:before {
  content: '\e9db'; }

.lnr-fingers-scroll-right2:before {
  content: '\e9dc'; }

.lnr-fingers-scroll-vertical3:before {
  content: '\e9dd'; }

.lnr-border-style:before {
  content: '\e9de'; }

.lnr-border-all:before {
  content: '\e9df'; }

.lnr-border-outer:before {
  content: '\e9e0'; }

.lnr-border-inner:before {
  content: '\e9e1'; }

.lnr-border-top:before {
  content: '\e9e2'; }

.lnr-border-horizontal:before {
  content: '\e9e3'; }

.lnr-border-bottom:before {
  content: '\e9e4'; }

.lnr-border-left:before {
  content: '\e9e5'; }

.lnr-border-vertical:before {
  content: '\e9e6'; }

.lnr-border-right:before {
  content: '\e9e7'; }

.lnr-border-none:before {
  content: '\e9e8'; }

.lnr-ellipsis:before {
  content: '\e9e9'; }

html {
  background-color: #2D2D2F;
  color: white;
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6; }

body {
  background-color: #2D2D2F;
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll !important;
  padding: 0 !important; }

main {
  padding: 0; }

button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

*,
*:before,
*:after {
  box-sizing: border-box; }

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0; }

a {
  color: #1578A2;
  transition: all 0.3s; }
  a:hover {
    text-decoration: none;
    color: #1ca0d8; }

img {
  display: block;
  height: auto;
  max-width: 100%; }

.btn-group-md.btn-group .btn {
  line-height: 22px;
  padding: 12px 15px; }

.filter-label {
  font-size: 16px;
  margin: 6px 00; }

label[for] {
  cursor: pointer; }

.thumbnail-container {
  position: relative; }
  .thumbnail-container.blank-slate {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.5;
    padding: 4px;
    text-align: center; }
  .thumbnail-container .duration {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 2px;
    color: white;
    font-size: 11px;
    left: 2px;
    line-height: 1;
    padding: 2px 6px;
    position: absolute; }

.modal-image {
  margin: auto;
  max-width: 600px; }

button.modal-close {
  background: white;
  border-radius: 50px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 32px; }
  button.modal-close span {
    color: black;
    position: relative;
    top: -2px; }

::selection {
  background: #58C1DB;
  color: #FFF; }

::-webkit-scrollbar {
  background-color: #24262B;
  box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
  height: 8px;
  padding: 1px;
  width: 8px; }

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2); }

.card ::-webkit-scrollbar {
  background-color: #F5F9F9;
  box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
  height: 8px;
  padding: 1px;
  width: 8px; }

.card ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2); }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.3em;
  margin-top: 0; }
  p .subhead,
  h1 .subhead,
  h2 .subhead,
  h3 .subhead,
  h4 .subhead,
  h5 .subhead,
  h6 .subhead {
    font-size: 0.8em; }
  p:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child {
    margin-bottom: 0; }

.typography--inline {
  display: flex;
  flex-wrap: wrap; }
  .typography--inline * {
    margin-top: auto;
    margin-right: 15px; }

.typography-card h1,
.typography-card h2,
.typography-card h3,
.typography-card h4,
.typography-card h5,
.typography-card h6 {
  margin-bottom: 10px; }

.highlight {
  background-color: #58CB87;
  color: #FFF; }

.page-subhead {
  margin: 0 0 20px; }
  .page-subhead.subhead {
    font-size: 14px;
    color: #999; }
  .page-subhead:last-child {
    margin-bottom: 20px; }

::selection {
  color: #FFF;
  background: #58C1DB; }

.account-page {
  background-color: #EBEDEF; }

.account {
  min-height: 100vh;
  display: flex;
  overflow: visible; }
  .account.account-photo {
    background-size: cover; }
    .account.account-photo .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #FFF; }
    .account.account-photo .form__form-group-icon,
    .account.account-photo .form__form-group-button {
      background: transparent; }
    .account.account-photo .form__form-group-icon,
    .account.account-photo input:not(:last-child) {
      border-right: none; }
    .account.account-photo .form__form-group-button,
    .account.account-photo input {
      border-left: none; }
    .account.account-photo .form__form-group-button.active {
      border-color: #EFF1F5; }
      .account.account-photo .form__form-group-button.active svg {
        fill: #58CB87; }
    .account.account-photo input {
      color: #FFF; }
      .account.account-photo input:focus {
        border-color: #EFF1F5; }
    .account.account-photo p {
      color: #FFF; }
    .account.account-photo .account__title {
      color: #FFF; }
  .account .account__wrapper {
    margin: 20px auto;
    position: relative;
    z-index: 1; }
  @media screen and (min-width: 0) {
    .account .account__head,
    .account .account-form {
      padding-left: 32px;
      padding-right: 32px; } }
  @media screen and (min-width: 760px) {
    .account .account__head,
    .account .account-form {
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 1200px) {
    .account .account__head,
    .account .account-form {
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (min-width: 0) {
    .account .account__head,
    .account .account-form {
      padding-bottom: 16px;
      padding-top: 16px; } }
  @media screen and (min-width: 760px) {
    .account .account__head,
    .account .account-form {
      padding-bottom: 20px;
      padding-top: 20px; } }
  @media screen and (min-width: 1200px) {
    .account .account__head,
    .account .account-form {
      padding-bottom: 30px;
      padding-top: 30px; } }
  .account .account__card {
    background-color: #F6F6F6;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1), 0 4px 32px rgba(0, 0, 0, 0.04);
    margin: auto;
    max-width: 520px;
    width: 100%; }
  .account .account__head {
    background-color: #2D2D2F; }
    .account .account__head .logo {
      display: block;
      margin: 0 auto;
      max-width: 290px;
      width: 100%; }
  .account .account__logo-accent {
    color: #586DDB; }
  .account .account__have-account {
    text-align: center;
    margin-top: 20px; }
    .account .account__have-account a {
      color: #586DDB;
      transition: all 0.3s; }
      .account .account__have-account a:hover {
        color: #3650d4;
        text-decoration: none; }
  .account .artwork-container {
    position: relative;
    z-index: 0; }
    .account .artwork-container .artwork {
      margin: 7% auto 0;
      max-height: 90vh; }

.btn-toolbar {
  margin-top: 1rem; }
  .btn-toolbar > .btn {
    margin-right: 1rem;
    margin-bottom: 1rem; }
    .btn-toolbar > .btn:last-child {
      margin-right: 0; }
  .btn-toolbar:first-child {
    margin-top: 0; }
  .btn-toolbar.btn-toolbar--center > * {
    margin-right: auto;
    margin-left: auto; }

.theme-light .btn-group {
  background-color: #F5F9F9; }

.theme-dark .btn-group {
  background-color: #1A1A1A; }

.theme-lightondark .btn-group {
  background-color: white; }

.btn-group .btn {
  margin-right: 0; }

.btn-group.justified {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .btn-group.justified .btn {
    width: 100%; }

.btn-group.icons .btn {
  padding: 7px 8px;
  line-height: 14px; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.unstyled-button {
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%; }

.card {
  border: 0;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.03), 0 0 10px 0 rgba(0, 0, 1, 0.02); }
  .card.clip {
    overflow: hidden; }
  .theme-light .card {
    background-color: white;
    color: #A3A3A3; }
  .theme-dark .card {
    background-color: #24262B;
    color: white; }
  .theme-lightondark .card {
    background-color: #F5F9F9;
    color: #444647; }
  .card.main-content-card {
    box-shadow: 0 10px 40px 1px rgba(0, 0, 0, 0.5); }
  .theme-light .card .card {
    background-color: #F5F9F9; }
  .theme-dark .card .card {
    background-color: #1A1A1A; }
  .theme-lightondark .card .card {
    background-color: white; }
  .card.bg-light .card-header,
  .card.bg-warning .card-header {
    border-color: rgba(0, 0, 0, 0.075); }
  .card.top-border {
    border-top: 0.4rem solid; }
  .card .card-title {
    margin-top: 2px;
    text-transform: uppercase;
    position: relative; }

.card-img-overlay {
  padding: 0; }

@media screen and (min-width: 0) {
  .card-body {
    padding: 16px; } }

@media screen and (min-width: 760px) {
  .card-body {
    padding: 20px; } }

@media screen and (min-width: 1200px) {
  .card-body {
    padding: 30px; } }

.card-body .card-body-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  @media screen and (min-width: 0) {
    .card-body .card-body-header {
      padding: 16px; } }
  @media screen and (min-width: 760px) {
    .card-body .card-body-header {
      padding: 20px; } }
  @media screen and (min-width: 1200px) {
    .card-body .card-body-header {
      padding: 30px; } }
  @media screen and (min-width: 0) {
    .card-body .card-body-header {
      margin-bottom: 16px; } }
  @media screen and (min-width: 760px) {
    .card-body .card-body-header {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .card-body .card-body-header {
      margin-bottom: 30px; } }
  @media screen and (min-width: 0) {
    .card-body .card-body-header {
      margin-left: -16px;
      margin-right: -16px;
      margin-top: -16px; } }
  @media screen and (min-width: 760px) {
    .card-body .card-body-header {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px; } }
  @media screen and (min-width: 1200px) {
    .card-body .card-body-header {
      margin-left: -30px;
      margin-right: -30px;
      margin-top: -30px; } }

@media screen and (min-width: 0) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-bottom: 8px;
    padding-top: 8px; } }

@media screen and (min-width: 760px) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-bottom: 10px;
    padding-top: 10px; } }

@media screen and (min-width: 1200px) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-bottom: 15px;
    padding-top: 15px; } }

@media screen and (min-width: 0) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-left: 16px;
    padding-right: 16px; } }

@media screen and (min-width: 760px) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-left: 20px;
    padding-right: 20px; } }

@media screen and (min-width: 1200px) {
  .card-header,
  .card-footer,
  .card-header + .card-body {
    padding-left: 30px;
    padding-right: 30px; } }

/*

labels
legends
fills
strokes
gradients

*/
.theme-light .chart path.recharts-sector {
  stroke: white; }

.theme-dark .chart path.recharts-sector {
  stroke: #24262B; }

.theme-lightondark .chart path.recharts-sector {
  stroke: #F5F9F9; }

.chart .recharts-text {
  fill: #A3A3A3;
  font-weight: 300;
  opacity: 0.7; }

.chart .recharts-cartesian-axis-line,
.chart .recharts-cartesian-axis-tick-line {
  stroke: #d1d1d1; }

.chart .recharts-cartesian-grid-horizontal line,
.chart .recharts-cartesian-grid-vertical line {
  fill: #d1d1d1;
  opacity: 0.4; }

.chart .recharts-brush-slide {
  fill: #DEE2E6;
  fill-opacity: 1; }

.chart .recharts-brush-traveller {
  transform: translateX(-8px); }
  .chart .recharts-brush-traveller rect {
    fill: white;
    stroke: #DEE2E6;
    width: 12px; }
  .chart .recharts-brush-traveller line {
    stroke: #DEE2E6;
    transform: translateX(4px); }
  .chart .recharts-brush-traveller:first-of-type {
    transform: translateX(0); }

.chart .recharts-tooltip-cursor {
  fill-opacity: 0.2; }

.chart .recharts-legend-wrapper {
  color: #555; }

.chart .recharts-default-tooltip {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #d1d1d1;
  padding: 0.5rem;
  white-space: nowrap; }
  .chart .recharts-default-tooltip .recharts-total-tooltip {
    margin: 0; }

.chart .chart-legend {
  padding: 1rem 0 0;
  list-style: none; }
  .chart .chart-legend li {
    margin-bottom: 0.5em; }
  .chart .chart-legend .legend-color-sample {
    border-radius: 50%;
    display: inline-block;
    height: 0.8em;
    margin-right: 0.5em;
    width: 0.8em; }

.dashboard .dashboard__visitors-chart-title span {
  color: #EBEBEB;
  font-size: 11px; }

.dashboard .dashboard__visitors-chart-number {
  font-size: 48px;
  line-height: 34px;
  margin-bottom: 10px; }

.dashboard .dashboard__audience-table .progress {
  margin-top: 10px; }

.dashboard .dashboard__audience-table .progress-bar {
  height: 10px; }

.dashboard .dashboard__table-flag {
  width: 42px;
  height: 30px;
  margin-right: 25px; }

.dashboard .dashboard__bounce-percent {
  color: #58CB87;
  font-size: 48px;
  line-height: 48px;
  margin-top: 20px;
  margin-bottom: 45px; }

.dashboard .dashboard__product-img {
  max-height: 270px;
  height: 100%;
  padding: 10px 0 20px;
  margin-bottom: 15px;
  text-align: center; }
  .theme-light .dashboard .dashboard__product-img {
    border-bottom: 1px solid #F5F9F9; }
  .theme-dark .dashboard .dashboard__product-img {
    border-bottom: 1px solid #EBEBEB; }
  .theme-lightondark .dashboard .dashboard__product-img {
    border-bottom: 1px solid #A3A3A3; }
  .dashboard .dashboard__product-img img {
    width: auto;
    max-width: 100%;
    max-height: 240px; }

.dashboard .dashboard__product-title {
  margin-bottom: 5px;
  text-transform: uppercase; }

.dashboard .dashboard__product-subhead {
  font-size: 13px;
  line-height: normal; }

.dashboard .dashboard__table-orders {
  overflow: hidden;
  min-width: 430px; }
  .dashboard .dashboard__table-orders tbody td {
    padding: 16px 10px; }
  .dashboard .dashboard__table-orders .dashboard__table-orders-title {
    position: relative;
    padding-left: 52px;
    display: flex;
    line-height: 15px;
    align-items: center; }

.dashboard .dashboard__table-orders-total {
  white-space: nowrap; }

.dashboard .dashboard__table-orders-img-wrap {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  left: 10px; }

.dashboard .dashboard__table-orders-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.dashboard .dashboard__table-orders-amount {
  display: flex; }
  .dashboard .dashboard__table-orders-amount div {
    width: 3px;
    height: 14px;
    display: inline-block;
    background-color: #58CB87;
    border-radius: 3px;
    margin: auto 3px auto 0; }
  .dashboard .dashboard__table-orders-amount span {
    color: #EBEBEB;
    margin: auto 0 auto 5px; }
  .dashboard .dashboard__table-orders-amount.dashboard__table-orders-amount--medium div {
    background-color: #F9F48D; }
  .dashboard .dashboard__table-orders-amount.dashboard__table-orders-amount--low div {
    background-color: #CC5B42; }

.dashboard .dashboard__table-orders-link {
  font-size: 12px;
  margin-top: 20px;
  display: block; }
  .dashboard .dashboard__table-orders-link svg {
    height: 12px;
    width: 12px; }

.dashboard .dashboard__table-more {
  display: flex; }
  .dashboard .dashboard__table-more > button {
    margin: 0;
    padding: 0 5px;
    border: none;
    background-color: transparent;
    margin-left: auto; }
    .dashboard .dashboard__table-more > button:before {
      display: none; }
    .dashboard .dashboard__table-more > button svg {
      margin: 0;
      height: 20px;
      width: 20px;
      fill: #EBEBEB;
      transition: all 0.3s; }
    .dashboard .dashboard__table-more > button:hover, .dashboard .dashboard__table-more > button:not([disabled]):not(.disabled):active, .dashboard .dashboard__table-more > button:focus {
      background: transparent; }
      .dashboard .dashboard__table-more > button:hover svg, .dashboard .dashboard__table-more > button:not([disabled]):not(.disabled):active svg, .dashboard .dashboard__table-more > button:focus svg {
        fill: #58CB87; }
  .dashboard .dashboard__table-more > div {
    min-width: 90px;
    width: 100%; }
  .dashboard .dashboard__table-more button {
    font-size: 13px; }

.dashboard .dashboard__sales-toggle label {
  margin: 0; }
  .dashboard .dashboard__sales-toggle label:after {
    box-shadow: none;
    background-color: #CC5B42; }

.dashboard .dashboard__sales-toggle-left {
  margin-left: auto;
  margin-right: 12px;
  color: #EBEBEB;
  pointer-events: none; }

.dashboard .dashboard__sales-toggle-right {
  margin-right: auto;
  margin-left: 12px;
  color: #EBEBEB;
  pointer-events: none; }

.dashboard .dashboard__sales-report {
  display: flex; }
  .dashboard .dashboard__sales-report > div {
    width: 100%;
    margin-right: 25px;
    margin-bottom: 0; }
    .dashboard .dashboard__sales-report > div:last-child {
      margin-right: 0; }
  .dashboard .dashboard__sales-report p {
    margin-top: 0; }

.dashboard .dashboard__sales-report-title {
  color: #EBEBEB;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 10px; }

.dashboard .dashboard__sales-report-now {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 4px; }

.dashboard .dashboard__sales-report-plan {
  font-size: 11px;
  line-height: 15px;
  color: #EBEBEB;
  opacity: 0.5;
  margin-bottom: 10px; }

.dashboard .dashboard__sales-report-value {
  margin-bottom: 0; }

.dashboard .dashboard__weight-stats {
  display: flex;
  margin-bottom: 20px; }

.dashboard .dashboard__weight-stat {
  margin-right: 40px; }
  @media screen and (min-width: 768px) {
    .dashboard .dashboard__weight-stat {
      margin-right: 80px; } }
  .dashboard .dashboard__weight-stat:last-child {
    margin-right: 0; }

.dashboard .dashboard__weight-stat-title {
  margin-top: 0;
  margin-bottom: 3px; }

.dashboard .dashboard__weight-stat-value {
  font-size: 36px;
  line-height: 48px; }
  @media screen and (min-width: 768px) {
    .dashboard .dashboard__weight-stat-value {
      font-size: 48px;
      line-height: 62px; } }
  .dashboard .dashboard__weight-stat-value.dashboard__weight-stat-value--control {
    color: #F9F48D; }
  .dashboard .dashboard__weight-stat-value.dashboard__weight-stat-value--total {
    color: #CC5B42; }

.dashboard .dashboard__health-chart-card {
  text-align: center; }

.dashboard .dashboard__goal {
  font-size: 12px;
  color: #EBEBEB; }

.dashboard .dashboard__health-chart {
  position: relative; }

.dashboard .dashboard__health-chart-info {
  position: absolute;
  width: 100%;
  top: calc(50% - 55px);
  animation: label 1.5s ease-in; }
  .dashboard .dashboard__health-chart-info p {
    margin: 0; }

.dashboard .dashboard__health-chart-number {
  font-size: 56px;
  line-height: 60px; }
  .theme-light .dashboard .dashboard__health-chart-number {
    color: #D3DADE; }
  .theme-dark .dashboard .dashboard__health-chart-number {
    color: white; }
  .theme-lightondark .dashboard .dashboard__health-chart-number {
    color: white; }

.dashboard .dashboard__health-chart-units {
  color: #EBEBEB;
  margin-top: 5px; }

.dashboard .dashboard__competitor {
  display: flex;
  padding: 10px 0;
  position: relative; }
  .dashboard .dashboard__competitor:before {
    content: '';
    position: absolute;
    height: 100%;
    width: calc(100% + 65px);
    top: 0;
    left: -30px;
    z-index: 0;
    opacity: 0;
    transition: all 0.3s; }
    .theme-light .dashboard .dashboard__competitor:before {
      background: #F5F9F9; }
    .theme-dark .dashboard .dashboard__competitor:before {
      background: #2D2D2F; }
    .theme-lightondark .dashboard .dashboard__competitor:before {
      background: #24262B; }
  .dashboard .dashboard__competitor:hover:before {
    opacity: 1; }

.dashboard .dashboard__competitor-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  min-width: 40px;
  z-index: 1; }
  .dashboard .dashboard__competitor-img img {
    height: 100%;
    min-width: 100%; }

.dashboard .dashboard__competitor-info {
  z-index: 1; }

.dashboard .dashboard__competitor-name {
  font-weight: 500; }

.dashboard .dashboard__competitor-result {
  color: #CC5B42;
  margin-top: 0;
  font-size: 14px; }

.dashboard .dashboard__area {
  font-size: 11px; }
  .dashboard .dashboard__area .recharts-legend-wrapper {
    bottom: 0 !important; }

.dashboard .dashboard__total {
  display: flex;
  margin-top: -15px; }
  .dashboard .dashboard__total.dashboard__total--area {
    margin-top: -55px; }

.dashboard .dashboard__chart-container {
  max-width: 180px; }

.dashboard .dashboard__total-stat {
  font-size: 18px;
  height: 24px;
  margin-right: auto;
  margin-top: auto;
  padding-right: 10px;
  line-height: 24px; }

.dashboard .dashboard__trend-icon {
  fill: #58CB87;
  height: 24px;
  width: 24px;
  margin-right: 3px;
  min-width: 24px;
  margin-top: auto; }

.dashboard .dashboard__stat {
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  align-items: center; }
  .dashboard .dashboard__stat:first-child {
    margin-top: 0; }
  .dashboard .dashboard__stat:last-child {
    margin-bottom: 0; }
  .dashboard .dashboard__stat .dashboard__stat-info {
    margin: auto 0 auto 30px; }
    .dashboard .dashboard__stat .dashboard__stat-info p {
      color: #EBEBEB; }
  .dashboard .dashboard__stat .dashboard__stat-number {
    margin-top: 10px; }
  .dashboard .dashboard__stat .dashboard__stat-chart {
    position: relative;
    text-align: center; }
  .dashboard .dashboard__stat .dashboard__stat-label {
    position: absolute;
    font-size: 18px;
    line-height: 20px;
    top: calc(50% - 10px);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    color: #DDD; }
  .dashboard .dashboard__stat.dashboard__stat--budget {
    text-align: center;
    flex-wrap: wrap; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-chart {
      margin: auto; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-label {
      font-size: 36px; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-main {
      width: 100%; }
      .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-main hr {
        margin-bottom: 30px;
        margin-top: 40px; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-main-title {
      color: #EBEBEB; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-main-number {
      color: #CC5B42;
      font-size: 48px;
      line-height: 34px;
      margin-top: 15px; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-data {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 10px; }
      .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-data p {
        margin: 0; }
    .dashboard .dashboard__stat.dashboard__stat--budget .dashboard__stat-data-number {
      font-size: 18px;
      line-height: 34px;
      font-weight: 500; }
  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    .dashboard .dashboard__stat {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px; }
      .dashboard .dashboard__stat .dashboard__stat-info {
        margin: 0; }
      .dashboard .dashboard__stat .dashboard__stat-chart {
        margin-bottom: 15px; } }
  @media screen and (min-width: 1200px) and (max-width: 1539px) {
    .dashboard .dashboard__stat .dashboard__stat-chart svg,
    .dashboard .dashboard__stat .dashboard__stat-chart div {
      width: 80px !important;
      height: 80px !important; } }

@keyframes label {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.dashboard .dashboard__chart-pie {
  height: 280px !important; }
  .dashboard .dashboard__chart-pie .recharts-surface {
    width: 100%;
    height: 100%; }
  @media screen and (min-width: 768px) {
    .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto {
      height: 300px !important; }
      .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .dashboard__chart-pie-container {
        height: 300px !important; }
    .dashboard .dashboard__chart-pie.dashboard__chart-pie--commerce {
      height: 360px !important; }
      .dashboard .dashboard__chart-pie.dashboard__chart-pie--commerce .dashboard__chart-pie-container {
        height: 360px !important; }
    .dashboard .dashboard__chart-pie.dashboard__chart-pie--fitness {
      height: 360px !important; }
      .dashboard .dashboard__chart-pie.dashboard__chart-pie--fitness .dashboard__chart-pie-container {
        height: 360px !important; } }
  @media screen and (max-width: 768px) {
    .dashboard .dashboard__chart-pie .recharts-legend-wrapper {
      position: relative !important; } }
  @media screen and (max-width: 480px) {
    .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto {
      height: 240px !important; }
      .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .dashboard__chart-pie-container {
        height: 180px !important; }
      .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .dashboard__chart-legend {
        margin-left: -10px; }
        .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .dashboard__chart-legend li {
          margin-top: 0; } }

.dashboard .dashboard__chart-pie-container {
  padding-left: 0;
  height: 200px !important; }

.dashboard .dashboard__chart-legend {
  padding: 0;
  list-style: none; }
  .dashboard .dashboard__chart-legend span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px; }
  .dashboard .dashboard__chart-legend li {
    margin-top: 5px; }
    .theme-light .dashboard .dashboard__chart-legend li {
      color: #A3A3A3; }
    .theme-dark .dashboard .dashboard__chart-legend li {
      color: white; }
    .theme-lightondark .dashboard .dashboard__chart-legend li {
      color: white; }

.dashboard .dashboard__card-widget {
  padding-top: 25px;
  padding-bottom: 25px; }

.dashboard .dashboard__btc {
  color: #F6A81E; }

.dashboard .dashboard__eth {
  color: #5E62E6; }

.dashboard .dashboard__neo {
  color: #3DDB42; }

.dashboard .dashboard__ste {
  color: #21CBE6; }

.dashboard .dashboard__eos {
  color: #6D6A6A; }

.dashboard .dashboard__lit {
  color: #B7B3B3; }

.dashboard .dashboard__table-crypto tbody td {
  padding: 17px 10px;
  white-space: nowrap; }

.dashboard .dashboard__table-crypto .dashboard__table-crypto-chart {
  padding: 10px;
  width: 180px; }

.dashboard .dashboard__table-crypto .dashboard__chart-container {
  max-width: 180px; }

.dashboard .dashboard__place-order {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (min-width: 900px) {
    .dashboard .dashboard__place-order {
      flex-wrap: nowrap; } }
  @media screen and (min-width: 1200px) {
    .dashboard .dashboard__place-order {
      flex-wrap: wrap; } }
  @media screen and (min-width: 1360px) {
    .dashboard .dashboard__place-order {
      flex-wrap: nowrap; } }

.dashboard .dashboard__booking-card {
  padding-top: 20px;
  padding-bottom: 25px;
  padding-right: 30px;
  padding-left: 30px; }
  .dashboard .dashboard__booking-card .dashboard__booking-card-progress-label {
    text-align: right;
    font-size: 14px;
    font-weight: 500; }
  .dashboard .dashboard__booking-card .dashboard__trend-icon {
    fill: #D8DFE9;
    margin-right: 0;
    margin-top: auto; }

.dashboard .dashboard__booking-total-container {
  display: flex;
  justify-content: space-between; }

.dashboard .dashboard__booking-total-title {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  color: #58CB87; }

.dashboard .dashboard__booking-total-title--red {
  color: #CC5B42; }

.dashboard .dashboard__booking-total-title--green {
  color: #58CB87; }

.dashboard .dashboard__booking-total-title--blue {
  color: #586DDB; }

.dashboard .dashboard__booking-total-description {
  opacity: 0.7;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #555;
  margin-top: 3px;
  text-transform: uppercase; }
  .theme-dark .dashboard .dashboard__booking-total-description {
    color: #DDD; }

.dashboard .dashboard__booking-reservations-panel:not(.panel--collapse) {
  height: calc(100% - 138px); }

.dashboard .dashboard__booking-reservations {
  margin-top: -10px; }
  .dashboard .dashboard__booking-reservations-title {
    font-size: 13px;
    color: #555; }
    .theme-dark .dashboard .dashboard__booking-reservations-title {
      color: #DDD; }
  .dashboard .dashboard__booking-reservations-number {
    font-size: 48px;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #555; }
    .theme-dark .dashboard .dashboard__booking-reservations-number {
      color: #DDD; }
  .dashboard .dashboard__booking-reservations-chart {
    height: 180px;
    position: relative; }
    @media screen and (min-width: 1400px) and (max-width: 1599px) {
      .dashboard .dashboard__booking-reservations-chart {
        height: 230px; } }
    .dashboard .dashboard__booking-reservations-chart .recharts-surface {
      width: 100%;
      height: 100%; }
    .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
      width: 90px !important;
      display: block;
      position: static !important; }
      @media screen and (min-width: 370px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
          width: 115px !important; } }
      @media screen and (min-width: 992px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
          width: 105px !important; } }
      @media screen and (min-width: 1020px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
          width: 115px !important; } }
      @media screen and (min-width: 1400px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
          width: 125px !important; } }
      @media screen and (min-width: 1800px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-legend-wrapper {
          width: 115px !important; } }
    .dashboard .dashboard__booking-reservations-chart .recharts-responsive-container {
      width: 100% !important;
      height: 100% !important; }
      @media screen and (min-width: 1400px) and (max-width: 1599px) {
        .dashboard .dashboard__booking-reservations-chart .recharts-responsive-container {
          height: calc(100% - 18px) !important; } }
    .dashboard .dashboard__booking-reservations-chart-container {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
      @media screen and (min-width: 992px) {
        .dashboard .dashboard__booking-reservations-chart-container {
          width: 100% !important;
          height: 100% !important; } }
      @media screen and (min-width: 1400px) and (max-width: 1599px) {
        .dashboard .dashboard__booking-reservations-chart-container {
          flex-direction: column;
          align-items: baseline; } }
    .dashboard .dashboard__booking-reservations-chart-legend li {
      display: flex;
      align-items: baseline; }
    .dashboard .dashboard__booking-reservations-chart-legend p {
      display: inline-block;
      margin: 0; }
  .dashboard .dashboard__booking-reservations-link {
    color: #48B5FF;
    font-size: 12px;
    line-height: 1.5;
    position: absolute;
    bottom: 0;
    left: 0; }
    .dashboard .dashboard__booking-reservations-link-icon {
      width: 12px;
      height: 12px; }
    @media screen and (min-width: 1400px) {
      .dashboard .dashboard__booking-reservations-link {
        margin-top: 10px;
        display: inline-block;
        position: static; } }

.dashboard .dashboard__booking-our-mission {
  height: 108px;
  background: #B8E986;
  border-radius: 5px;
  padding: 15px 13px;
  position: relative;
  overflow: hidden; }
  .dashboard .dashboard__booking-our-mission svg {
    position: absolute;
    height: 80px;
    width: 80px;
    top: calc(50% - 40px);
    opacity: 0.3;
    right: -23px;
    color: #FFF; }
  .dashboard .dashboard__booking-our-mission p {
    margin: 0;
    font-size: 16px;
    line-height: 1.25;
    color: #FFF;
    max-width: 100%; }
    @media screen and (min-width: 376px) and (max-width: 991px) {
      .dashboard .dashboard__booking-our-mission p {
        max-width: calc(100% - 60px); } }
    @media screen and (min-width: 1200px) and (max-width: 1550px) {
      .dashboard .dashboard__booking-our-mission p {
        font-size: 14px; } }
    @media screen and (max-width: 420px) {
      .dashboard .dashboard__booking-our-mission p {
        font-size: 14px; } }
  .dashboard .dashboard__booking-our-mission .dashboard__booking-our-mission-title {
    font-size: 24px;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 4px; }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      .dashboard .dashboard__booking-our-mission .dashboard__booking-our-mission-title {
        font-size: 20px; } }

.dashboard .dashboard__weekly-stat hr {
  margin-bottom: 20px; }

.dashboard .dashboard__weekly-stat-chart {
  display: flex;
  margin: 0 0 31px;
  justify-content: space-around; }
  .dashboard .dashboard__weekly-stat-chart .recharts-surface {
    width: 100%;
    height: 100%; }
  .dashboard .dashboard__weekly-stat-chart-item {
    width: 110px; }
    @media screen and (max-width: 370px) {
      .dashboard .dashboard__weekly-stat-chart-item {
        margin-left: 10px; } }
    @media screen and (min-width: 1200px) {
      .dashboard .dashboard__weekly-stat-chart-item {
        margin-left: 10px; } }
    @media screen and (min-width: 1400px) {
      .dashboard .dashboard__weekly-stat-chart-item {
        margin-left: 0; } }
    .dashboard .dashboard__weekly-stat-chart-item:first-child {
      margin-left: 0; }
  .dashboard .dashboard__weekly-stat-chart-pie {
    display: block;
    position: relative;
    text-align: center;
    height: 110px; }
    .dashboard .dashboard__weekly-stat-chart-pie .recharts-responsive-container {
      width: 100% !important; }
  .dashboard .dashboard__weekly-stat-chart-pie-wrapper {
    width: 100% !important;
    height: 100% !important; }

.dashboard .dashboard__weekly-stat-label {
  position: absolute;
  top: calc(50% - 10px);
  width: 100%;
  margin: 0;
  animation: label 1.5s ease-in;
  font-size: 24px;
  line-height: 24px; }

.dashboard .dashboard__weekly-stat-info {
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.38;
  text-align: center; }
  .dashboard .dashboard__weekly-stat-info p {
    color: #EBEBEB; }

.dashboard .dashboard__social-stat-item {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 10px; }
  .dashboard .dashboard__social-stat-item:first-child {
    margin-top: 0; }

.dashboard .dashboard__social-stat-title {
  width: 40%;
  font-size: 13px;
  line-height: 1.38;
  color: #EBEBEB;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dashboard .dashboard__social-stat-progress {
  width: 60%;
  margin-bottom: 5px; }
  .dashboard .dashboard__social-stat-progress.progress--wide {
    width: 80%; }
  .dashboard .dashboard__social-stat-progress .progress__label {
    text-align: right; }

.dashboard .dashboard__occupancy-table td {
  font-size: 13px;
  padding: 5px; }

.dashboard .dashboard__occupancy-table td.td-head {
  color: #555; }
  .theme-dark .dashboard .dashboard__occupancy-table td.td-head {
    color: #DDD; }

.dashboard .dashboard__occupancy-table td.td-blue {
  font-weight: 500;
  color: #48B5FF; }

.dashboard .dashboard__occupancy-table td.td-green {
  font-weight: 500;
  color: #B8E986; }

.dashboard .dashboard__occupancy-table td.td-gray {
  color: #999; }

.dashboard .dashboard__place-order-form {
  position: relative;
  width: 100%;
  margin-top: 20px; }
  .dashboard .dashboard__place-order-form:first-child {
    margin-top: 0; }
  .dashboard .dashboard__place-order-form form {
    padding: 20px 20px 30px;
    border-radius: 5px;
    margin-top: 10px; }
    .theme-light .dashboard .dashboard__place-order-form form {
      background-color: #F5F9F9; }
    .theme-dark .dashboard .dashboard__place-order-form form {
      background-color: #EBEBEB; }
    .theme-lightondark .dashboard .dashboard__place-order-form form {
      background-color: #A3A3A3; }
  .dashboard .dashboard__place-order-form .form__form-group-label {
    width: 50px; }
  .dashboard .dashboard__place-order-form .form__form-group-field {
    width: calc(100% - 50px); }
  .dashboard .dashboard__place-order-form .form__button-toolbar {
    margin-left: 0;
    width: 100%; }
    .dashboard .dashboard__place-order-form .form__button-toolbar button {
      width: 100%; }
  .theme-light .dashboard .dashboard__place-order-form input {
    background-color: white; }
  .theme-dark .dashboard .dashboard__place-order-form input {
    background-color: #24262B; }
  .theme-lightondark .dashboard .dashboard__place-order-form input {
    background-color: #24262B; }
  @media screen and (min-width: 900px) {
    .dashboard .dashboard__place-order-form {
      width: calc(50% - 15px);
      margin-top: 0; } }
  @media screen and (min-width: 1200px) {
    .dashboard .dashboard__place-order-form {
      width: 100%;
      margin-top: 20px; }
      .dashboard .dashboard__place-order-form:first-child {
        margin-top: 0; } }
  @media screen and (min-width: 1360px) {
    .dashboard .dashboard__place-order-form {
      width: calc(50% - 15px);
      margin-top: 0; } }

.dashboard .dashboard__place-order-form-subhead {
  position: absolute;
  font-size: 12px;
  top: 4px;
  right: 0; }

@media screen and (min-width: 1200px) {
  .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .dashboard__chart-pie-container {
    padding-left: 0; }
  .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .recharts-legend-wrapper {
    bottom: -110px !important; } }

@media screen and (min-width: 1360px) {
  .dashboard .dashboard__chart-pie.dashboard__chart-pie--crypto .recharts-legend-wrapper {
    bottom: 5px !important; } }

.dashboard .mobile-app-widget .mobile-app-widget__top-line {
  display: flex; }
  .dashboard .mobile-app-widget .mobile-app-widget__top-line .mobile-app-widget__total-stat {
    padding: 0;
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-right: auto;
    line-height: normal; }

.dashboard .mobile-app-widget .mobile-app-widget__top-line--pink .mobile-app-widget__total-stat {
  color: #FF4861; }

.dashboard .mobile-app-widget .mobile-app-widget__top-line--lime .mobile-app-widget__total-stat {
  color: #B8E986; }

.dashboard .mobile-app-widget .mobile-app-widget__top-line--blue .mobile-app-widget__total-stat {
  color: #48B5FF; }

.dashboard .mobile-app-widget .mobile-app-widget__top-line--turquoise .mobile-app-widget__total-stat {
  color: #4CE1B6; }

.dashboard .mobile-app-widget .mobile-app-widget__title {
  text-transform: uppercase;
  position: relative;
  margin: 0 0 20px; }
  .dashboard .mobile-app-widget .mobile-app-widget__title h5 {
    font-size: 12px;
    color: #555;
    font-weight: 500; }

.dashboard .mobile-app-widget .dashboard__trend-icon {
  margin: 0;
  height: 29px;
  min-width: 28px;
  fill: #D8DFE9; }

.dashboard .mobile-app-widget .progress__label {
  font-size: 14px;
  font-weight: 500;
  right: -7px; }

.dashboard .mobile-app-widget .progress-bar {
  border-radius: 5px;
  background-color: unset; }

.dashboard .dashboard__users-stat .dashboard__trend-icon {
  fill: #d1d1d1;
  height: 24px;
  width: 24px;
  margin-right: 3px;
  min-width: 24px;
  margin-top: auto; }

.dashboard .dashboard__current-users {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .dashboard .dashboard__current-users .dashboard__current-users-chart {
    position: relative;
    width: 100%; }
    .dashboard .dashboard__current-users .dashboard__current-users-chart div,
    .dashboard .dashboard__current-users .dashboard__current-users-chart svg {
      width: 100% !important;
      margin: 0 auto; }
    .dashboard .dashboard__current-users .dashboard__current-users-chart .dashboard__current-users-label {
      margin: 0;
      position: absolute;
      top: calc(50% + 10px);
      text-align: center;
      left: calc(50% - 30px);
      font-size: 38px; }
  .dashboard .dashboard__current-users .dashboard__current-users-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-size: 13px;
    line-height: 1.54;
    color: #555; }
    .dashboard .dashboard__current-users .dashboard__current-users-info p {
      margin: 0; }
    .dashboard .dashboard__current-users .dashboard__current-users-info .dashboard__current-users-day {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .dashboard .dashboard__current-users .dashboard__current-users-info .dashboard__current-users-day span:last-child {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 500;
        color: #555; }
  @media screen and (min-width: 1539px) {
    .dashboard .dashboard__current-users .dashboard__current-users-info {
      padding: 0 20px; } }
  @media screen and (min-width: 1200px) and (max-width: 1539px) {
    .dashboard .dashboard__current-users .dashboard__current-users-chart svg,
    .dashboard .dashboard__current-users .dashboard__current-users-chart div {
      height: 100px !important; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .dashboard .dashboard__current-users .dashboard__current-users-info {
      padding: 0 75px; } }

.dashboard .dashboard__active-users-chart {
  overflow: hidden; }

.dashboard .dashboard__carousel {
  margin-bottom: -25px; }
  .dashboard .dashboard__carousel .slick-dots {
    bottom: -35px; }
  .dashboard .dashboard__carousel .slick-arrow.slick-prev,
  .dashboard .dashboard__carousel .slick-arrow.slick-next {
    background: none;
    opacity: 0;
    pointer-events: none; }
  .dashboard .dashboard__carousel .dashboard__carousel-slide {
    border-radius: 5px;
    padding: 15px 13px;
    position: relative;
    overflow: hidden; }
    .dashboard .dashboard__carousel .dashboard__carousel-slide svg {
      position: absolute;
      height: 80px;
      width: 80px;
      top: calc(50% - 40px);
      opacity: 0.3;
      right: -20px; }
      .theme-light .dashboard .dashboard__carousel .dashboard__carousel-slide svg {
        fill: #EBEBEB; }
      .theme-dark .dashboard .dashboard__carousel .dashboard__carousel-slide svg {
        fill: #AB6DC8; }
      .theme-lightondark .dashboard .dashboard__carousel .dashboard__carousel-slide svg {
        fill: #586DDB; }
    .dashboard .dashboard__carousel .dashboard__carousel-slide p {
      color: #EBEBEB;
      margin: 0; }
    .dashboard .dashboard__carousel .dashboard__carousel-slide .dashboard__carousel-title {
      color: #CC5B42;
      font-size: 24px;
      font-weight: 500; }
    .theme-light .dashboard .dashboard__carousel .dashboard__carousel-slide {
      background: white; }
    .theme-dark .dashboard .dashboard__carousel .dashboard__carousel-slide {
      background: #2D2D2F; }
    .theme-lightondark .dashboard .dashboard__carousel .dashboard__carousel-slide {
      background: #2D2D2F; }
    .dashboard .dashboard__carousel .dashboard__carousel-slide.dashboard__carousel-slide--red {
      background: #CC5B42; }
      .dashboard .dashboard__carousel .dashboard__carousel-slide.dashboard__carousel-slide--red p {
        color: white;
        opacity: 0.45; }
      .dashboard .dashboard__carousel .dashboard__carousel-slide.dashboard__carousel-slide--red svg {
        fill: #FFF; }
      .dashboard .dashboard__carousel .dashboard__carousel-slide.dashboard__carousel-slide--red .dashboard__carousel-title {
        opacity: 1; }

@media screen and (min-width: 760px) {
  input, .form-control, input {
    font-size: 0.75rem; } }

select:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 203, 135, 0.5); }

@media screen and (min-width: 760px) {
  form .horizontal .input-container {
    padding: 9px 0; } }

form input.input-icon-left,
form textarea.input-icon-left,
form .react-select .select__control.input-icon-left {
  border-radius: 0.2rem;
  padding-left: 54px; }

form input.input-icon-right, form input.form-control:not(:last-child), form input:not(:last-child), form input.custom-select:not(:last-child),
form textarea.input-icon-right,
form textarea.form-control:not(:last-child),
form textarea.custom-select:not(:last-child),
form .react-select .select__control.input-icon-right,
form .react-select .select__control.form-control:not(:last-child),
form .react-select input.select__control:not(:last-child),
form .react-select .select__control.custom-select:not(:last-child) {
  padding-right: 54px; }

form input:placeholder,
form textarea:placeholder,
form .react-select .select__control:placeholder {
  color: #A3A3A3; }

form input:focus, form input:active,
form textarea:focus,
form textarea:active,
form .react-select .select__control:focus,
form .react-select .select__control:active {
  outline: none;
  border-color: #58CB87; }

form textarea {
  min-height: 85px;
  resize: vertical; }
  form textarea.form-control::-webkit-scrollbar {
    box-shadow: -1px 1px 7px rgba(0, 0, 0, 0.15) inset;
    height: initial;
    padding: initial;
    width: initial; }
  form textarea.form-control::-webkit-scrollbar-thumb {
    border-radius: initial;
    background-color: rgba(0, 0, 0, 0.2); }

form input[type='radio'],
form input[type='checkbox'] {
  height: auto; }

form .action-group {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px; }

form .input-group-append,
form .input-group-prepend {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px 10px;
  position: absolute; }
  form .input-group-append .mdi-icon,
  form .input-group-prepend .mdi-icon {
    margin: 0; }

form .input-group-prepend {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0;
  left: 0; }

form .input-group-append {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0.2rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0.2rem;
  right: 0; }

form.form--horizontal .form__form-group {
  display: flex;
  flex-wrap: wrap; }

form.form--horizontal .form__form-group-field {
  width: calc(100% - 80px);
  padding-left: 10px;
  margin-left: 80px; }

form.form--horizontal .form__form-group-label {
  width: 80px;
  margin: auto 0; }
  form.form--horizontal .form__form-group-label + .form__form-group-field {
    margin-left: 0; }

form.form--horizontal .form__form-group-description {
  margin-left: 90px; }

form.form--horizontal .form__button-toolbar {
  margin-left: 0; }

@media screen and (min-width: 480px) {
  form.form--horizontal .form__form-group-label {
    width: 120px; }
  form.form--horizontal .form__form-group-field {
    width: calc(100% - 120px);
    margin-left: 120px;
    padding-left: 20px; }
  form.form--horizontal .form__form-group-description,
  form.form--horizontal .form__button-toolbar {
    margin-left: 140px; } }

form.form--preview {
  display: flex; }
  form.form--preview > div:nth-child(2) {
    margin-right: 50px; }
  form.form--preview .form__form-group {
    margin-bottom: 10px;
    width: auto;
    min-height: 18px; }
  form.form--preview .form__select-color {
    display: flex;
    flex-wrap: wrap;
    max-width: 84px;
    margin-right: 40px; }
  form.form--preview p {
    margin-bottom: 10px; }

form.form--justify {
  display: flex;
  flex-wrap: wrap; }
  form.form--justify .form__form-group {
    width: 33.3333%; }
  form.form--justify .form__button-toolbar {
    width: 100%; }

form .form__form-group-select {
  width: 100%;
  height: 32px; }
  form .form__form-group-select .Select-control {
    height: 32px;
    border-radius: 0;
    transition: all 0.3s;
    background: transparent; }
    .theme-light form .form__form-group-select .Select-control {
      border: 1px solid #F2F4F7; }
    .theme-dark form .form__form-group-select .Select-control {
      border: 1px solid #D3DADE; }
    .theme-lightondark form .form__form-group-select .Select-control {
      border: 1px solid #A3A3A3; }
  form .form__form-group-select .Select-placeholder,
  form .form__form-group-select .Select-input {
    height: 30px; }
  form .form__form-group-select .Select-input {
    width: 100%; }
  form .form__form-group-select input {
    width: 100% !important;
    border: none;
    padding: 0; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-input {
    width: inherit; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-value {
    background: transparent;
    border-color: #586DDB; }
  .theme-light form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: #A3A3A3 !important; }
  .theme-dark form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: white !important; }
  .theme-lightondark form .form__form-group-select .Select-multi-value-wrapper .Select-value-label {
    color: #444647 !important; }
  form .form__form-group-select .Select-multi-value-wrapper .Select-value-icon {
    border-color: #586DDB; }
  form .form__form-group-select .Select-menu-outer {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    animation: open 0.3s ease-in-out;
    overflow: hidden; }
    .theme-light form .form__form-group-select .Select-menu-outer {
      border: 1px solid #F5F9F9;
      background: #F5F9F9; }
    .theme-dark form .form__form-group-select .Select-menu-outer {
      border: 1px solid #EBEBEB;
      background: #1A1A1A; }
    .theme-lightondark form .form__form-group-select .Select-menu-outer {
      border: 1px solid #A3A3A3;
      background: white; }

@keyframes open {
  0% {
    max-height: 0; }
  100% {
    max-height: 200px; } }
  form .form__form-group-select .Select-option {
    transition: all 0.3s;
    border-radius: 0;
    display: flex; }
    .theme-light form .form__form-group-select .Select-option {
      background: #F5F9F9;
      color: #A3A3A3; }
    .theme-dark form .form__form-group-select .Select-option {
      background: #1A1A1A;
      color: white; }
    .theme-lightondark form .form__form-group-select .Select-option {
      background: white;
      color: #444647; }
    .theme-light form .form__form-group-select .Select-option.is-focused {
      background: #F5F9F9; }
    .theme-dark form .form__form-group-select .Select-option.is-focused {
      background: #1A1A1A; }
    .theme-lightondark form .form__form-group-select .Select-option.is-focused {
      background: white; }
  form .form__form-group-select.is-focused .Select-control, form .form__form-group-select.is-focused:not(.is-open) .Select-control {
    border-color: #58CB87;
    box-shadow: none;
    background: transparent; }
  form .form__form-group-select .form__form-group-select-color {
    display: block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: auto 0 auto 5px; }

.form__form-group {
  padding-bottom: 12px;
  padding-top: 7px;
  position: relative;
  width: 100%; }
  .theme-light .form__form-group {
    color: #A3A3A3;
    border-bottom: 1px solid #F2F4F7; }
  .theme-dark .form__form-group {
    color: white;
    border-bottom: 1px solid #D3DADE; }
  .theme-lightondark .form__form-group {
    color: #444647;
    border-bottom: 1px solid white; }
  @media screen and (min-width: 760px) {
    .form__form-group {
      padding-bottom: 9px;
      padding-top: 9px; }
      .form__form-group .align-items-top .form__form-group-label {
        padding-top: 16px; } }
  .form__form-group.toggle-container {
    padding-bottom: 7px; }
  .form__form-group.borderless, .form__form-group:last-of-type {
    border-bottom: 0; }

.form__form-group--address input:last-child {
  margin-left: 15px;
  width: 70%; }

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative; }

.form__form-group-label {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-top: 6px; }
  @media screen and (min-width: 760px) {
    .form__form-group-label {
      padding-bottom: 9px;
      padding-top: 9px; } }
  .theme-light .form__form-group-label {
    color: #A3A3A3; }
  .theme-dark .form__form-group-label {
    color: white; }
  .theme-lightondark .form__form-group-label {
    color: #444647; }
  .form__form-group-label .form__form-group-help-text,
  .form__form-group-label .form__form-group-optional-flag {
    color: #A3A3A3; }

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s; }
  .form__form-group-button svg {
    width: 18px;
    height: 18px;
    transition: all 0.3s; }
  .form__form-group-button.active {
    background: #58CB87;
    border: 1px solid #58CB87; }
    .form__form-group-button.active svg {
      fill: #FFF; }

.form__form-group-file label {
  border-radius: 2px;
  line-height: 18px;
  padding: 4px 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center; }
  .theme-light .form__form-group-file label {
    border: 1px solid #F2F4F7;
    color: #A3A3A3; }
  .theme-dark .form__form-group-file label {
    border: 1px solid #D3DADE;
    color: white; }
  .theme-lightondark .form__form-group-file label {
    border: 1px solid #A3A3A3;
    color: #444647; }
  .theme-light .form__form-group-file label:hover {
    background: #F2F4F7; }
  .theme-dark .form__form-group-file label:hover {
    background: #D3DADE; }
  .theme-lightondark .form__form-group-file label:hover {
    background: #A3A3A3; }

.form__form-group-file span {
  padding-left: 10px; }

.form__form-group-file input {
  display: none; }

.file-preview {
  align-content: center;
  border-radius: 0.25rem;
  border: 1px solid #A3A3A3;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 12px;
  position: relative;
  width: 100px; }
  .file-preview:before {
    background-color: rgba(25, 25, 25, 0.6);
    bottom: 0;
    content: '';
    cursor: default;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 2; }
  .file-preview:hover:before {
    opacity: 1; }
  .file-preview:hover .remove,
  .file-preview:hover .file-name {
    opacity: 1; }
  .file-preview .image-component {
    border-radius: 0.1875rem; }
  .file-preview .file-thumbnail {
    overflow: hidden;
    z-index: 1; }
  .file-preview .file-name {
    color: white;
    display: block;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 3; }
  .file-preview .remove {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    transition: opacity 0.25s ease-in-out;
    z-index: 3; }
  .file-preview .placeholder-icon {
    color: #A3A3A3;
    font-size: 48px;
    margin: auto;
    transform: translateY(-1px); }

.form__form-group-icon {
  height: 32px;
  padding: 6px;
  pointer-events: none; }
  .theme-light .form__form-group-icon {
    background: white;
    border: 1px solid #A3A3A3; }
  .theme-dark .form__form-group-icon {
    background: #24262B;
    border: 1px solid #A3A3A3; }
  .theme-lightondark .form__form-group-icon {
    background: #F5F9F9;
    border: 1px solid #A3A3A3; }
  .form__form-group-icon svg {
    fill: #7d7d7d;
    width: 18px;
    height: 18px;
    transition: all 0.3s; }

.form__form-group-description {
  font-size: 10px;
  color: #EBEBEB;
  line-height: 13px;
  margin-top: 2px; }

.form__button-toolbar {
  margin-top: 10px; }

.form__form-group-input-wrap {
  width: 100%; }

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #AD4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px; }

.form__form-group-date-cvc {
  display: flex;
  width: 100%; }
  .form__form-group-date-cvc .form__form-group-date {
    width: 100%;
    margin-right: 20px; }
  .form__form-group-date-cvc .form__form-group-cvc {
    max-width: 280px;
    width: 100%; }
  @media screen and (max-width: 767px) {
    .form__form-group-date-cvc {
      flex-wrap: wrap; }
      .form__form-group-date-cvc .form__form-group-date {
        margin-right: 0; }
      .form__form-group-date-cvc .form__form-group-cvc {
        max-width: 100%; } }

.form__form-group-id-category {
  width: 100%;
  display: flex; }
  .form__form-group-id-category .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .form__form-group-id-category {
      flex-wrap: wrap; }
      .form__form-group-id-category .form__form-group-id {
        margin-right: 0;
        width: 100%; } }

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px; }
  .form__form-group-price-discount > div {
    margin-bottom: 0; }
  .form__form-group-price-discount .form__form-group-price {
    margin-right: 20px; }
  @media screen and (max-width: 767px) {
    .form__form-group-price-discount {
      flex-wrap: wrap; }
      .form__form-group-price-discount .form__form-group-price {
        margin-right: 0; } }

.radio-button-container .input-container {
  float: left; }

.was-validated .form-control:valid, .was-validated input:valid,
.was-validated input:valid, .form-control.is-valid, input.is-valid,
input.is-valid, .was-validated .form-control:invalid, .was-validated input:invalid,
.was-validated input:invalid, .form-control.is-invalid, input.is-invalid,
input.is-invalid, .was-validated textarea.form-control:valid, textarea.form-control.is-valid, .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right 4px top 7px; }

.container {
  padding-left: 16px;
  padding-right: 16px; }

@media screen and (min-width: 760px) {
  .row {
    margin-left: -10px;
    margin-right: -10px; }
    .row.no-gutters {
      margin-left: 0;
      margin-right: 0; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col,
  .col-auto,
  .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px; } }

@media screen and (min-width: 1200px) {
  .row {
    margin-left: -15px;
    margin-right: -15px; }
    .row.no-gutters {
      margin-left: 0;
      margin-right: 0; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col,
  .col-auto,
  .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px; } }

.container__wrap {
  padding-left: 0;
  padding-top: 76px;
  padding-bottom: 16px;
  min-height: 100vh;
  transition: padding-left 0.3s; }
  .theme-light .container__wrap {
    background: white;
    color: #A3A3A3; }
  .theme-dark .container__wrap {
    background: #2D2D2F;
    color: white; }
  .theme-lightondark .container__wrap {
    background: #2D2D2F;
    color: white; }
  @media screen and (min-width: 570px) {
    .container__wrap {
      padding-left: 244px;
      padding-right: 8px; } }
  @media screen and (min-width: 760px) {
    .container__wrap {
      padding-left: 246px;
      padding-top: 80px; } }
  @media screen and (min-width: 1200px) {
    .container__wrap {
      padding-left: 251px;
      padding-top: 90px; } }

.layout.layout--collapse + .container__wrap {
  padding-left: 0; }
  @media screen and (min-width: 570px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 68px; } }
  @media screen and (min-width: 760px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 70px; } }
  @media screen and (min-width: 1200px) {
    .layout.layout--collapse + .container__wrap {
      padding-left: 75px; } }

@media screen and (min-width: 570px) {
  .layout.layout--top-navigation + .container__wrap {
    padding-left: 0; } }

.not-found {
  text-align: center;
  height: 100vh;
  overflow: auto;
  display: flex;
  background-size: cover; }
  .not-found button {
    margin: 0; }

.not-found__content {
  margin: auto;
  padding: 10px; }

.not-found .not-found__info {
  color: #FFF;
  margin-bottom: 20px;
  margin-top: 90px; }

.not-found__image {
  max-width: 500px;
  width: 100%; }

.bg-primary {
  color: #FFF !important; }

.bg-secondary {
  color: #FFF !important; }

.bg-success {
  color: #FFF !important; }

.bg-info {
  color: #FFF !important; }

.bg-warning {
  color: #444647 !important; }

.bg-danger {
  color: #FFF !important; }

.bg-light {
  color: #444647 !important; }

.bg-dark {
  color: #FFF !important; }

.container-aspect-ratio {
  height: 0;
  position: relative; }
  .container-aspect-ratio .contain-image {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); }

.container-16x9 {
  padding-bottom: 56.25%; }

.container-9x16 {
  padding-bottom: 177.77778%; }

.container-1x1 {
  padding-bottom: 100%; }

.container-4x3 {
  padding-bottom: 75%; }

.container-3x4 {
  padding-bottom: 133.33333%; }
