// Cards
$card-spacer-y: rem(20);
$card-spacer-x: $grid-gutter-width;
$card-border-width: $border-width;
$card-border-radius: $border-radius-lg;
$card-border-color: rgba(128, 128, 128, 0.075);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-bg: $white;
$card-cap-bg: inherit;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width;
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;
