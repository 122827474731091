@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.account-select-button {
  align-items: center;
  background-color: $white;
  border: 1px solid $border-color-light;
  box-shadow: 0 0 0 0 $white, 0 0 0 0 $green;
  display: flex;
  padding: 6px 12px 6px 6px;
  transition: box-shadow 0.2s;
  will-change: box-shadow;

  &.selected {
    box-shadow: 0 0 0 2px $white, 0 0 0 6px $green;
  }

  h4 {
    flex: 1;
    margin: 0 0 0 12px;
    word-break: break-word;
  }

  .profile-pic {
    max-width: 48px;
  }
}
