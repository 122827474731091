@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.folder-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ($spacer / 2) 0 ($spacer / 2) ($spacer / 4);

  @include themify($themes) {
    border-bottom: 1px solid themed('cardColorHover');
  }

  &.active {
    @include themify($themes) {
      background-color: themed('cardColorHover');
    }

    .folder-title {
      color: $text-color;
    }
  }

  .asset-icon {
    color: $text-muted;
    font-size: em(16, 14);
    transform: translateY(-1px);

    &.folder-icon {
      color: rgba($yellow, 0.8);
    }
  }

  .folder-title {
    color: $text-color-medium;
    flex: 100%;
    padding: 0 ($spacer / 2);

    &:hover {
      color: $text-color;
      text-decoration: underline;
    }
  }

  .btn-icon {
    border-radius: 1em;
    line-height: 1;
    margin-right: ($spacer / 4);
    padding: ($spacer / 4);
    transform: rotateX(0);
    transition: transform 0.4s ease-in-out;
    will-change: transform;

    &.open {
      transform: rotateX(180deg);
    }

    .lnr {
      height: 1em;
      line-height: inherit;
      width: 1em;
    }
  }
}
