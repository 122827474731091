@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.page-title {
  font-weight: $font-weight-normal;
  font-size: (20 / $rem-value * 1rem);
  margin: 0 0 8px;

  &:last-child {
    @include gutter-sizing('margin-bottom');
  }
}
