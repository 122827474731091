// Paragraphs
//
// Style p element.

$line-height-base: (18/12);
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$paragraph-margin-bottom: ($line-height-lg * 1em);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Poppins', -apple-system, blinkmacsystemfont,
  'Segoe UI', roboto, 'Helvetica Neue', arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: sfmono-regular, menlo, monaco, consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

// Font sizes for base, buttons, sm & lg is in metrics (font-size note)

// These correspond to the weights loaded for Gotham
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

// px value on the left, output in rem
$h1-font-size: (36 / $rem-value * 1rem);
$h2-font-size: (30 / $rem-value * 1rem);
$h3-font-size: (24 / $rem-value * 1rem);
$h4-font-size: (18 / $rem-value * 1rem);
$h5-font-size: (13 / $rem-value * 1rem);
$h6-font-size: (11 / $rem-value * 1rem);

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.333333;
$headings-color: inherit;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: $font-weight-light;
$display2-weight: $font-weight-light;
$display3-weight: $font-weight-light;
$display4-weight: $font-weight-light;
$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);
$lead-font-weight: $font-weight-light;

$small-font-size: $font-size-sm;

$text-muted: $text-color-muted;

$blockquote-small-color: $text-color-muted;
$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $text-color-muted;
$hr-border-width: 1px;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #FCF8E3;

$hr-margin-y: $spacer;
