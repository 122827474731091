// Modals

// Padding applied to the modal body
$modal-inner-padding: $grid-gutter-width;

$modal-dialog-margin: $spacer * 2;
$modal-dialog-margin-y-sm-up: $spacer * 2;

$modal-title-line-height: $line-height-base;

$modal-content-bg: $white;
$modal-content-border-color: rgba($border-color-light, 0.3);
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);

$modal-backdrop-bg: $background-gray-dark-1;
$modal-backdrop-opacity: 0.7;
$modal-header-border-color: rgba($border-color-light, 0.3);
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: $spacer;

$modal-lg: 1880px;
$modal-md: 838px;
$modal-sm: 600px;

$modal-transition: transform 0.3s ease-out;
