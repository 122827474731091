@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.story-editor {
  .page {
    @include gutter-sizing([ 'margin-top', 'margin-right' ]);
    flex: 0 0 auto;
    height: (102px * 16/9);
    position: relative;
    width: 102px;
  }

  .story-book {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .btn-add-page {
    border: 2px dashed $border-color-light;
    bottom: 0;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    .mdi-icon {
      height: 48px;
      max-width: 100%;
      width: 48px;
    }
  }
}
