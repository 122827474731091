@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.table-toolbar {
  @media screen and (min-width: $bp-md) {
    align-items: center;
  }

  > [class*='col'] {
    @include gutter-sizing('margin-bottom');
  }
}
