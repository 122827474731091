@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.panel.blank-slate {
  @include gutter-sizing('margin-bottom');
  align-items: center;
  box-shadow: none;
  justify-content: center;

  .card-title,
  .card-text,
  .card-body {
    text-align: center;
    text-transform: none;
  }
}
