@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.tabs {
  overflow: auto;

  .nav-tabs {
    flex-wrap: nowrap;

    &.sticky-nav {
      @include themify($themes) {
        background-color: themed('cardBackground');
        box-shadow: 0 1px 30px themed('cardBackground');
      }
      position: sticky;
      top: 59px;
      z-index: 1;
    }

    &.numbered {
      .nav-item {
        padding-right: 30px;

        .nav-link {
          align-items: center;
          display: flex;
          font-size: 20px;
          font-weight: normal;
          line-height: (28/20);
          padding: 2px 20px 5px 0;
          text-align: left;
          width: 100%;
        }
      }
    }

    .tab-number {
      align-items: center;
      background-color: #D3DADE;
      border-radius: 14px;
      color: $text-color-medium;
      display: flex;
      font-size: 18px;
      height: 28px;
      justify-content: center;
      margin-right: 8px;
      width: 28px;
    }

    li {
      .nav-link {
        background-color: transparent;
        cursor: pointer;
        margin: 0 4px;
        transition: background-color 0.3s, border-color 0.3s;

        @include themify($themes) {
          color: themed('cardText');
        }

        &:hover,
        &:focus,
        &:active {
          background-color: rgba($text-color, 0.05);
          border-color: $border-color-light;
          outline: none;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          background-color: transparent;
          border-color: $border-color-light;

          @include themify($themes) {
            border-bottom-color: themed('cardBackground');
          }
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    @include gutter-sizing('padding-top');

    &.save-and-publish {
      max-width: 500px;
      margin-bottom: 20px;

      @media screen and (min-width: $bp-xl) {
        margin-bottom: 30px;
      }
    }
  }

  &.bordered-top .nav-tabs li .nav-link {
    border-top: 2px solid transparent;
    border-radius: 0;

    &.active,
    &:focus,
    &:hover {
      border-top: 2px solid $color-accent;
    }
  }

  &.bordered-bottom .nav-tabs li .nav-link {
    border-color: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    margin: 0;

    &.active {
      &,
      &:focus,
      &:hover {
        border-radius: 0;
        border-color: transparent;
        border-bottom: 3px solid $color-accent;
      }
    }
  }

  &.fieldset {
    .nav-tabs {
      border-color: $border-color;

      li .nav-link {
        background-color: transparent;
        border-bottom: 3px solid transparent;
        border-top: 0;
        color: $text-color-muted;
        font-size: 20px;
        line-height: (26/20);
        margin: 0;

        @include gutter-sizing('margin-right');
        padding: 0 0 4px;
        position: relative;

        @media screen and (min-width: $bp-md) {
          font-size: $h3-font-size;
          line-height: (30/24);
          padding: 0 0 5px;
        }

        &:focus,
        &:hover {
          border-radius: 0;
          @include themify($themes) {
            border-bottom: 3px solid themed('colorBorder');
          }
        }

        &.active,
        &.active:focus,
        &.active:hover {
          @include themify($themes) {
            color: themed('cardText');
            border-bottom: 3px solid themed('colorBorder');
          }
          border-radius: 0;
        }
      }
    }
  }

  .tab-buttons {
    @include gutter-sizing([ 'padding-top', 'padding-bottom' ]);
    border-top: 1px solid $border-color-light;
    display: flex;
    justify-content: space-between;
    overflow: visible;
  }
}

// Fix for franchise input at the bottom of Movies form

.form {
  .tabs,
  .tabs-wrap,
  .tab-content,
  .tab-pane {
    overflow: visible;
  }
}
