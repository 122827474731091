@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

dl {
  margin-bottom: 0;

  dt {
    padding-right: 0.5em;
  }

  > div {
    padding-bottom: $spacer / 2;
  }

  dt,
  dd {
    display: inline;
    font-weight: $font-weight-normal;
  }

  dd {
    color: $text-color-medium;
  }

  &.definition-table {
    display: table;

    > div {
      display: table-row;
    }

    dt,
    dd {
      display: table-cell;
      padding-bottom: $spacer / 2;
    }

    dt {
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
