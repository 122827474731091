@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.ReactTable {
  display: flex;
  flex-direction: column;
  position: relative;

  * {
    box-sizing: border-box;
  }

  .rt-table {
    align-items: stretch;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    flex: auto 1;
    overflow: auto;
    width: 100%;
  }

  .rt-thead {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    user-select: none;

    &.-headerGroups {
      background: rgba($text-color, 0.03);
      border-bottom: 1px solid $border-color-table;
    }

    &.-filters {
      border-bottom: 1px solid $border-color-table;
    }

    &.-header {
      border-bottom: 1px solid $border-color-table;
    }

    .rt-th,
    .rt-td {
      box-shadow: inset 0 0 0 0 transparent;
      line-height: normal;
      padding: 12px 24px;
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .column-name {
      .sort-icon {
        opacity: 0.5;

        &.lnr-arrow-down {
          margin-left: -0.25em;
          transition: opacity 0.15s;
        }
      }
    }

    .rt-th.-sort-asc,
    .rt-td.-sort-asc,
    .rt-th.-sort-desc,
    .rt-td.-sort-desc {
      .column-name {
        .sort-icon.lnr-arrow-up {
          display: inline-block;
          opacity: 1;
          transition: transform $transition-duration ease;

          .sort-icon.lnr-arrow-down {
            opacity: 0;
          }
        }

        .lnr-arrow-down {
          opacity: 0;
          transition: opacity 0.15s;
        }
      }
    }

    .rt-th.-sort-desc,
    .rt-td.-sort-desc {
      .column-name {
        .sort-icon {
          transform: rotate(180deg);
        }
      }
    }

    .rt-th.-cursor-pointer,
    .rt-td.-cursor-pointer {
      cursor: pointer;
    }

    .rt-th:focus {
      outline: none;
    }

    .rt-resizable-header {
      overflow: visible;
    }

    .rt-resizable-header:last-child {
      overflow: hidden;
    }

    .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rt-header-pivot:after,
    .rt-header-pivot:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      left: 100%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: 0;
    }

    .rt-header-pivot:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #FFF;
      border-width: 8px;
      margin-top: -8px;
    }

    .rt-header-pivot:before {
      border-color: rgba(102, 102, 102, 0);
      border-left-color: #F7F7F7;
      border-width: 10px;
      margin-top: -10px;
    }
  }

  .rt-tbody {
    display: flex;
    flex-direction: column;
    flex: 99999 1 auto;
    overflow: auto;
    min-height: 60px;
  }

  .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba($text-color, 0.05);
  }

  .rt-tbody .rt-tr-group:last-child {
    border-bottom: 0;
  }

  .rt-tbody .rt-expandable {
    cursor: pointer;
    text-overflow: clip;
  }

  .rt-tr-group {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .rt-tr {
    display: inline-flex;
    flex: 1 0 auto;
  }

  .rt-th,
  .rt-td {
    align-items: center;
    display: flex;
    flex: 1 0 0;
    padding: 12px 24px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }

  .table-actions {
    justify-content: flex-end;

    .btn {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rt-td.table-actions {
    padding: 4px 24px;

    .btn-icon {
      background-color: inherit;
      border-color: transparent;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        background-color: $border-color-table;
      }

      svg {
        height: 22 / 16 * 1em;
      }
    }
  }

  .rt-th.-hidden,
  .rt-td.-hidden {
    border: 0 !important;
    min-width: 0 !important;
    opacity: 0 !important;
    padding: 0 !important;
    width: 0 !important;
  }

  .rt-expander {
    color: transparent;
    display: inline-block;
    margin: 0 10px;
    margin: 0;
    position: relative;
  }

  .rt-expander:after {
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba($text-color, 0.8);
    content: '';
    cursor: pointer;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 0;
  }

  .rt-expander.-open:after {
    transform: translate(-50%, -50%) rotate(0);
  }

  .rt-resizer {
    bottom: 0;
    cursor: col-resize;
    display: inline-block;
    position: absolute;
    right: -18px;
    top: 0;
    width: 36px;
    z-index: 10;
  }

  .rt-tfoot {
    box-shadow: 0 0 15px 0 rgba($text-color, 0.15);
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &.-striped .rt-tr.-odd {
    @include themify($themes) {
      background: themed('cardColorHover');
    }
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba($text-color, 0.05);
  }

  .rt-noData {
    color: $orange;
    display: block;
    left: 50%;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    top: 40px;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    z-index: 1;
    text-align: center;
    width: 100%;
  }

  .-loading {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
    z-index: -1;
  }

  .-loading > div {
    color: rgba($text-color, 0.6);
    display: block;
    font-size: 14px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%;
  }

  .-loading.-active {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
  }

  .-loading.-active > div {
    transform: translateY(50%);
  }

  .rt-resizing .rt-th,
  .rt-resizing .rt-td {
    cursor: col-resize;
    transition: none !important;
    user-select: none;
  }

  .post-details {
    max-width: 100%;

    h4,
    small {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
