@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.data-table-pagination {
  .next-prev,
  .page-settings {
    @include gutter-sizing('margin-top');
  }

  .page-settings,
  .page-jump-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-jump {
    width: 64px;
    margin-left: ($spacer / 2);
    margin-right: ($spacer / 2);
  }

  .next,
  .previous {
    @media screen and (max-width: $bp-md) {
      order: 2;
    }
  }
}
