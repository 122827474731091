@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.ad-summary {
  @include gutter-sizing('margin-top');
  border-radius: $border-radius;
  border: 1px solid $border-color;
  box-shadow: 0 0 0 rgba($green, 0);
  display: flex;
  transition: border-color $transition-duration-sm ease-in-out,
    box-shadow $transition-duration-sm ease-in-out;

  &.selectable {
    appearance: none;
    padding: 0;
    text-align: left;
    width: 100%;

    .ad-info {
      padding-right: 42px;
    }

    &:focus {
      @include focus-shadow($green);
      outline: 0;
    }

    &.selected {
      border-color: $green;
      box-shadow: 0 0 10px rgba($green, 0.6);

      .thumbnail {
        border-color: $green;
      }
    }
  }

  .thumbnail {
    border-right: 1px solid $border-color;
    width: 74px;
  }

  .ad-info {
    padding: 8px 12px;

    .ad-sponsor {
      display: block;
    }

    .ad-title {
      margin: 0;
      font-size: 15px;
      line-height: (20/15);
    }
  }

  .selected-checkmark {
    color: $green;
    opacity: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $transition-duration-sm ease-in-out;
  }

  &.selected {
    .selected-checkmark {
      opacity: 1;
    }
  }
}
