@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.page-thumbnail {
  .page-name {
    display: block;
    margin-top: 0.5em;
    text-align: center;
  }

  .thumbnail-container {
    background-color: $background-gray-light-2;
    position: relative;
    overflow: hidden;
  }

  .image-component {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .actions-overlay {
    background: rgba($background-gray-dark-1, $modal-backdrop-opacity);
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity $transition-duration ease-in-out;
    width: 100%;
    z-index: 2;

    &:hover {
      opacity: 1;
    }

    .actions {
      background: transparent;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
