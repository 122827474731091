// Links
//
// Style anchor elements.

$link-color: #1578A2;
$link-color-light: $cyan;
$link-decoration: none;
$link-hover-color: lighten($link-color, ($theme-color-interval * 1.5));
$link-color-light: lighten($link-color-light, $theme-color-interval);
$link-hover-decoration: underline;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: rem(6);
$btn-padding-x: rem(24);
$btn-font-family: $input-btn-font-family;
$btn-font-size: $font-size-base;
$btn-line-height: (22/16);

$btn-padding-y-sm: $input-btn-padding-y;
$btn-padding-x-sm: $font-size-base;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
