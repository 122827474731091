.container {
  padding-left: 16px;
  padding-right: 16px;
}

// Responsive grid spacing
@media screen and (min-width: $bp-md) {
  .row {
    margin-left: -$grid-gutter-width-md / 2;
    margin-right: -$grid-gutter-width-md / 2;

    &.no-gutters {
      margin-left: 0;
      margin-right: 0;
    }
  }

  %md-padding {
    padding-right: $grid-gutter-width-md / 2;
    padding-left: $grid-gutter-width-md / 2;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @extend %md-padding;
      }
    }
  }

  .col,
  .col-auto,
  .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    @extend %md-padding;
  }
}

@media screen and (min-width: $bp-xl) {
  .row {
    margin-left: -$grid-gutter-width-xl / 2;
    margin-right: -$grid-gutter-width-xl / 2;

    &.no-gutters {
      margin-left: 0;
      margin-right: 0;
    }
  }

  %xl-padding {
    padding-right: $grid-gutter-width-xl / 2;
    padding-left: $grid-gutter-width-xl / 2;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @extend %xl-padding;
      }
    }
  }

  .col,
  .col-auto,
  .container,
  .form-row > .col,
  .form-row > [class*='col-'] {
    @extend %xl-padding;
  }
}

// EasyDev styles

.container__wrap {
  padding-left: 0;
  padding-top: ($topbar-height + $grid-gutter-width);
  padding-bottom: $grid-gutter-width;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
    color: themed('colorText');
  }

  @media screen and (min-width: $bp-sm) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width / 2));
    padding-right: ($grid-gutter-width / 2);
  }

  // Will have to update manually
  @media screen and (min-width: $bp-md) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width-md / 2));
    padding-top: ($topbar-height + $grid-gutter-width-md);
  }

  @media screen and (min-width: $bp-xl) {
    padding-left: ($sidebar-expanded-width + ($grid-gutter-width-xl / 2));
    padding-top: ($topbar-height + $grid-gutter-width-xl);
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: $bp-sm) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width / 2));
      }

      // Will have to update manually
      @media screen and (min-width: $bp-md) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width-md / 2));
      }

      @media screen and (min-width: $bp-xl) {
        padding-left: ($sidebar-collapsed-width + ($grid-gutter-width-xl / 2));
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: $bp-sm) {
        padding-left: 0;
      }
    }
  }
}
